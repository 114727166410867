import i18n from "i18next";
import { LOCAL_STORAGE, DEFAULT_LANGUAGE } from "../constants";

const resources = {
  en: {
    translation: {
      days: "days",
      hours: "hours",
      minutes: "minutes",
      seconds: "seconds",
      left: "left",
      leftToEnd: "Until the end of the promotion",
      day1: "days",
      day2: "days",
      day3: "days",
      finished: "finished",
      promoDetails: "Detailes of the promotion",
      promoFrom: "The promotion is valid from",
      promoTo: "to",
      promoFinished: "Finished",
      emptyStateTitle: "The list is empty",
      emptyStateDesc:
        "Sorry, there are no promotions available at the moment. Please try again later or contact our support team for more information.",
      productsCatalog: "Products catalog",
      catalog: "Catalog",
      news: "News",
      warrantyConditions: "Public offer agreement",
      privacyPolicy: "Privacy Policy",
      privacyPolicyText: `
			<p>Denis Matiushenko is the owner and created the "Apulse" app as a free app. This SERVICE is provided by Denis Matiushenko at no cost and is intended for use as is.
				
				This page is used to inform visitors regarding our policies on the collection, use, and disclosure of Personal Information if anyone decides to use our Service.
				
				If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
				
				The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible unless otherwise defined in this Privacy Policy.</p>
			<h3>Collection and Use of Information</h3>
			<p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information [add everything you collect here, e.g., username, address, location, images]. The information that we request will be [stored on your device and not collected by us in any way].
				
				The app does use third-party services that may collect information used to identify you.
			
				Links to the privacy policies of third-party service providers used by the app</p>
				<ul>
					<li>Google Play Services</li>
					<li>Firebase Crashlytics</li>
					<li>Log Data</li>
				</ul>
				<p>We want to inform you that whenever you use our Service, in case of an error in the app, we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device’s Internet Protocol (“IP”) address, device name, operating system version, app configuration when utilizing [my/our] Service, the time and date of your use of the Service, and other statistics.</p>
			<h3>Cookies</h3>
			<p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. They are sent to your browser from the websites you visit and are stored on your device’s internal memory.

			This Service does not explicitly use these "cookies." However, the application may utilize third-party code and libraries that use cookies to collect information and improve their services. You have the option to accept or decline these cookies and be notified when a cookie is sent to your device. If you choose to decline our cookies, you may not be able to use certain parts of this Service.</p>
			<h3>Service Providers</h3>
			<p>[I/We] may employ third-party companies and individuals for the following reasons:</p>
			<ul>
				<li>To facilitate the Service;</li>
				<li>To provide the Service on our behalf;</li>
				<li>To perform Service-related services; or</li>
				<li>To assist us in analyzing how our Service is used.</li>
				<li>[I/We] want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</li>
			</ul>
			<h3>Security</h3>
			<p>[I/We] value your trust in providing us with your Personal Information, thus we strive to use commercially acceptable means to protect it. But remember that no method of transmission over the Internet or method of electronic storage is 100% secure and reliable, and [I/We] cannot guarantee its absolute security.</p>
			<h3>Links to Other Sites</h3>
			<p>This Service may contain links to other sites. If you click on an external link, you will be directed to that site. Note that these external sites are not operated by [me/us]. Therefore, [I/We] strongly advise you to review the Privacy Policy of these websites. [I/We] have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
			<h3>Children’s Privacy</h3>
			<p>These Services are not intended for individuals under the age of 13. [I/We] do not knowingly collect personal information from children under 13 years of age. In the case [I/We] discover that a child under 13 has provided us with personal information, [I/We] will immediately delete it from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact [me/us] so that [I/We] can take necessary actions.</p>
			<h3>Changes to This Privacy Policy</h3>
			<p>[I/We] may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. [I/We] will notify you of any changes by posting the new Privacy Policy on this page.
			
			This policy is effective as of March 20, 2023.</p>
			<h3>Contact Us</h3>
			<p>If you have any questions or suggestions about [my/our] Privacy Policy, do not hesitate to contact [me/us] at .</p>`,
      aboutUs: "About us",
      support: "Support",
      contacts: "Contacts",
      contactUs: "Contact us",
      search: "Search",
      info: "Information",
      allRightsReserved: "All rights reserved",
      followUsOnSocial: "Download Our App",
      promotions: "Promotions",
      promo: "Promo",
      pair: "In a pair",
      popularModels: "Popular models",
      sale: "Sale",
      allProducts: "All products",
      newsAndArticles: "News and articles",
      sortBy: "Sort by",
      ASC: "Low to High",
      DESC: "High to Low",
      promoASC: "Promotion Low to High",
      promoDESC: "Promotion High to Low",
      select: "Select",
      resetFilters: "Reset filters",
      apply: "Apply",
      price: "Price",
      available: "Available",
      notAvailable: "Not available",
      alreadyInCart: "Product already in cart",
      aviableNotify: "Get notified about product availability",
      alreadyNotify:
        "We'll send you a notification as soon as the product is back in stock",
      productCode: "Product code",
      buy: "Buy",
      characteristics: "Characteristics",
      description: "Description",
      reviews: "Reviews",
      writeReview: "Write a review",
      leaveFeedback: "Leave feedback",
      name: "Name",
      openTgBot: "Open telegram bot",
      surname: "Surname",
      email: "Email",
      mark: "Mark",
      message: "Message",
      showInstruction: "Show instruction",
      minimalCount: "Minimum order quantity for this product",
      noReviews: "There are no reviews for this product. You can be the first!",
      goToHomePage: "Go to home page",
      title404: "Page not Found",
      message404: "Sorry, we can’t find the page you’re looking for.",
      queryResult: "Results for the query",
      queryNoResult: "No results for the query",
      noResultMessage1:
        "Unfortunately, we could not find any products matching your search.",
      noResultMessage2: "Please try adjusting your search parameters.",
      searchMinLengthError: "Search query must be at least 2 characters long.",
      login: "Log in",
      register: "Register",
      forgotPass: "Forgot Password",
      pass: "Password",
      emailOrPhone: "Email or Phone number in the format +380",
      continue: "Continue",
      phone: "Phone",
      confirmPass: "Confirm password",
      country: "Country",
      region: "Region",
      terms: "I have read and agree with",
      termsWord: "privacy policy",
      passwordnotSame: "Passwords are not the same",
      passwordLength: "Password should be at least 6 characters long",
      company: "Company",
      yourName: "Your name",
      yourSurname: "Your Surname",
      enterCompany: "Enter company name",
      enter: "Enter",
      error: "Error!",
      chooseCountry: "Please choose a country",
      chooseCity: "Please choose a city",
      chooseWarehouse: "Please choose a Nova Poshta warehouse",
      chooseState: "Please choose a state",
      invalidPhone: "Invalid phone number format",
      confirm: "Confirm",
      messagesIsRead: "All messages have been read!",
      confirmCodeDescr:
        "Please enter the verification code sent to your phone number.",
      confirmCodeDescrEmail:
        "Please enter the verification code sent to email address.",
      enterCode: "Your verification code",
      phoneCode: "Phone code",
      success: "Success!",
      notEmailOrPhone:
        "Unfortunately, you have entered an invalid email or phone number format. Please check the information you entered and try again.",
      resendCode: "Resend code",
      maxResendCount: "Maximum resend count exceeded.",
      codeAgainSend:
        "Code successfully resent! Please check your phone to receive a new confirmation code.",
      codeAgainSendEmail:
        "Code successfully resent! Please check your email to receive a new confirmation code.",
      profile: "Profile",
      favorites: "Selected products",
      cart: "Cart",
      logOut: "Log out",
      yes: "Yes",
      no: "No",
      loginOrRegisterTitle: "Log in to continue",
      loginOrRegister: "Log in or register",
      quantity: "Quantity",
      remove: "Remove",
      send: "Send",
      setArticleRating:
        "Help us enhance our content by providing a rating for this news.",
      emptyFavorites: "No items in your favorites yet.",
      emptyFavorites2:
        " Find a product you love and add it to your favorites for quick access.",
      successAddedFavorite:
        "The item has been successfully added to favorites.",
      successAddedWaiting:
        "The item has been successfully added to waiting list.",
      successDeleteFavorite:
        "The item has been successfully removed from favorites.",
      successDeleteWaiting:
        "The item has been successfully removed from waiting list.",
      successAddedToOrder: "The item has been successfully added to cart.",
      attention: "Attention",
      afterRegisterText:
        "You will be contacted within two business days to clarify the information.",
      ok: "Okay",
      reviewMessage: "Write your review here...",
      reviewSent: "Thank you for your review!",
      reviewSentInfo:
        "After being checked by a moderator, it will be published.",
      cancel: "Сancel",
      orders: "My orders",
      clearCart: "Clear the cart",
      toOrdering: "Order",
      emptyCart1: "You don't have any items in your cart yet.",
      emptyCart2: "Find the products you like and add them to your cart.",
      retailPrice: "Retail price",
      wholesalePrice: "Wholesale price",
      largeWholesalePrice: "Partner",
      partnerPrice: "Partner price",
      specialPrice: "Special price",
      total: "Total",
      emptyOrders1: "You don't have any orders yet.",
      emptyOrders2: "Make your first order to see it here.",
      emptyClosedOrders: "You don't have any closed orders yet.",
      pending: "Pending",
      closed: "Closed",
      piece: "pc.",
      status: "Status",
      comment: "Comment",
      yourComment: "Your comment",
      yourMessage: "Your message",
      yourAddress: "Your address",
      writeUs: "Write us",
      discount: "Discount",
      paymentMethod: "Payment Method",
      fact: "Factual",
      nonFact: "Not factual",
      workHour: "Working hours",
      workHourVal: "Mon-Sat: 9:00-21:00, Sunday: day off.",
      saleDep: "Sales department",
      techDep: "Technical support",
      orderType: "Delivery method",
      delivery: "Delivery of Nova Poshta by courier",
      pickup: "Pickup (Kharkiv city)",
      deliveryToWarehouse: "Delivery to a Nova Poshta warehouse",
      city: "City",
      state: "State",
      warehouse: "Warehouse",
      address: "Address",
      processOrder: "Place an order",
      factPay:
        "Payment upon delivery (without payment deferral). A 5% discount on the cost is provided",
      chooseOrderType: "Choose delivery method",
      warehouseNP: "To a Nova Poshta warehouse",
      courierNP: "By Nova Poshta courier",
      pickupAddress:
        'Kharkiv city, 28B Vashchenkivsky lane, 1st floor, "Avtozvuk" store',
      address2: '28 "B" Vashchenkivskyi lane, Kharkiv, Ukraine',
      pickupText1: "After placing an order",
      pickupStrongText:
        "marked goods are reserved for you for a period of 3 days.",
      pickupText2:
        "At the end of the term, if the goods are not sold, the reservation is canceled and the goods can be sold to another buyer.",
      totalSum: "Total summary",
      addedToOrder: "In the cart",
      currentOrders: "Сurrent orders",
      historyOrders: "Orders history",
      thanksForOrder: "Thank you for your order!",
      order: "Order",
      orderInfo:
        "Our manager will contact you to clarify the details of the order.",
      uah: "UAH",
      грн: "UAH",
      ye: "c.u.",
      change: "Change",
      noChange: "No Data Changes Occurred",
      confirmEmail: "Please Confirm Your Email.",
      confirmEmailText1:
        "We have sent you an email with a confirmation link. Please follow the link to confirm your email.",
      confirmEmailText2:
        "If you haven't received the email, please check your 'Spam' folder or contact our support team.",
      notifications: "Notifications",
      emptyNotifications: "You don't have any notifications yet.",
      emptyNotifyProducts:
        "You haven't added any products to the notification list yet.",
      developed: "Developed",
      promoCode: "Promo Code",
      applied: "applied",
      notifyTitle: "Waiting list",
      downloadPriceList: "Download price list",
      downloadShortPriceList: "Download the shortened price list",
      downloadXML: "Download XML file",
      guiideByChoice: "Guide by choice",
      waitTwoMinutes: "Please wait, the loading may take about 2 minutes",
      publicOfferAgreement: "Public offer agreement",
      successConfirmEmail:
        "You have successfully confirmed your email address!",
      failConfirmEmail: "Unfortunately, we couldn't confirm the email address.",
      warehouseAvailable: "Availability in warehouses",
      storage: "Warehouse",
      availability: "Availability",
      allWarehouses: "All warehouses",
      toOrder: "To order 2-3 days",
      noResultByFilter:
        "We're sorry, but we couldn't find any matching products for the filter you selected.",
      errorAuth: "Something went wrong. Contact the support team.",

      this_phone_number_is_already_in_use:
        "This phone number is already in use",
      this_email_is_already_in_use: "This email is already in use",
      this_email_already_in_use: "This email is already in use",
      today_max_phone_code_amount_expired:
        "Today's maximum limit of phone code requests has been exceeded",
      code_is_expite_or_wrong: "Code has expired or entered incorrectly",
      login_or_password_wrong: "Incorrect login or password",
      user_not_found: "User not found",
      please_confirm_your_phone: "Please confirm your phone number.",
      agreeLogout: "Are you sure you want to log out?",
      agreeDelete: "Are you sure you want to delete all products from cart?",
      agreeClearAllMessages: "Would you like to make all messages read?",
      agreeDeleteOne: "Are you sure you want to delete this product?",
      promo_code_not_found:
        "Promo Code Not Found. Please double-check the code you entered and try again",
      promo_code_expired:
        "Oops! The promo code you are trying to use has expired.",
      promo_code_usage_amount_exhausted:
        "Unfortunately, the number of uses of this promo code has been exhausted. Please try another promo code.",
      you_already_use_this_promo_code: "You have already used this promo code.",
      promo_code_start_date_has_not_yet_arrived:
        "Promo code start date has not yet arrived.",
      warrantyTermsTitle0: "Public offer agreement",
      warrantyTermsText0: `Under this Agreement, one party, the Seller, on the one hand, and any person who has accepted the terms of this Public Offer Agreement - the Buyer, on the other hand, hereinafter referred to together as the Parties, have entered into this Public Offer Agreement (hereinafter referred to as the Agreement) addressed to an unlimited number of persons, which is the Seller's official public offer to conclude a contract of sale of the Goods with the Buyers, the photos of which are posted in the relevant section of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">https://apulse.com.ua/</a>.
Sellers who intend to sell the Goods through the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website and Buyers who purchase the Goods whose images are posted on the relevant pages of <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> accept the terms of this Agreement on the following.`,
      warrantyTermsTitle1: "GENERAL PROVISIONS",
      warrantyTermsText1: `The contractual relationship between the Seller and the Buyer is formalized in the form of a Public Offer Agreement. Clicking the "ORDER" button on the page of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> in the appropriate section means that the Buyer, regardless of status (individual, legal entity, individual entrepreneur), in accordance with applicable international and Ukrainian legislation, has accepted the terms of the Public Offer Agreement, which are listed below.
1.2. The Public Offer Agreement is public, that is, in accordance with Articles 633, 641 of the Civil Code of Ukraine, its terms are the same for all Buyers regardless of status (individual, legal entity, individual entrepreneur). In case of full agreement with this Agreement, the Buyer accepts the terms and conditions of ordering, payment and delivery of the goods by the Seller, liability for an unfair Order and for failure to comply with the terms of this Agreement.
1.3. This Agreement shall enter into force from the moment you click on the "ORDER" button, by which the Buyer agrees to purchase the Goods available from the Seller and is valid until the Buyer receives the Goods from the Seller and makes full payment to him.
1.4. To regulate the contractual legal relations under the Agreement, the Parties shall choose and, if necessary, apply Ukrainian legislation. If an international treaty, ratified by the Verkhovna Rada of Ukraine, establishes rules other than those established by Ukrainian legislation, the rules of the international treaty shall apply.`,
      warrantyTermsTitle2: "TERMS AND DEFINITIONS",
      warrantyTermsText2: `"Public Offer Agreement" - a public agreement, a sample of which is posted on the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website and the use of which is mandatory for all Sellers, containing the Seller's offer to purchase the Goods, the image of which is posted on the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website, directed to an indefinite number of persons, including Buyers.
"Acceptance" - acceptance by the Buyer of the Seller's offer to purchase the Goods, the image of which is posted on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, by adding it to the virtual basket and sending the Order.
"Goods" - a trade item (product, model, accessory, components and related items, any other trade items), the purchase of which is posted on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> seller's offer.
"Buyer" - any legally capable individual, legal entity, individual entrepreneur, in accordance with applicable international and Ukrainian law, who has visited the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website and intends to purchase a particular Product.
"Seller" means any capable individual, legal entity, individual entrepreneur, in accordance with the current international and Ukrainian legislation, who owns or distributes the Goods and intends to sell them through the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website.
"Order" - a duly executed and posted on the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website application of the Buyer for the purchase of the Goods addressed to the Seller.
"Legislation" - the norms established by Ukrainian or international legislation for the regulation of contractual legal relations under the Agreement.
"Significant defect of the Goods" - a defect that makes it impossible or unacceptable to use the Goods in accordance with their intended purpose, arose through the fault of the manufacturer (Seller), after its elimination it manifests itself again for reasons beyond the consumer's control.`,
      warrantyTermsTitle3: "CONTRACT SUBJECT",
      warrantyTermsText3: `3.1. The Seller undertakes to sell the Goods on the terms and in the manner specified in this Agreement on the basis of the Order placed by the Buyer on the relevant page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website, and the Buyer undertakes to buy the goods and pay for them on the terms and in the manner specified in this Agreement.
3.2. The Seller warrants that the Goods are not pledged, are not subject to a dispute, are not under arrest, and that there are no rights of third parties to them.
3.3 The Seller and the Buyer confirm that the current Agreement is not a fictitious or sham Agreement or an Agreement concluded under the influence of pressure or fraud.
3.4. The Seller confirms that it has all the necessary permits for economic activity, which regulate the scope of legal relations arising and operating in the course of the execution of this Agreement, and also guarantees that it has the right to manufacture and / or sell the goods without any restrictions, in accordance with the requirements of the current legislation of Ukraine, and undertakes to bear responsibility in case of violation of the Buyer's rights in the course of execution of this Agreement and sale of the Goods.`,
      warrantyTermsTitle4: "RIGHTS AND OBLIGATIONS OF THE SELLER",
      warrantyTermsText4: `4.1 The Seller is obliged to:
      <ul>
				<li>fulfill the terms of this Agreement</li>
        <li>fulfill the Buyer's order in case of receipt of payment from the Buyer;</li>
        <li>transfer the Goods to the Buyer in accordance with the selected sample on the corresponding page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website, the placed order and the terms of this Agreement;</li>
        <li>check the qualitative and quantitative characteristics of the Goods during its packaging in the warehouse;</li>
			</ul>
4.2. The Seller has the right to:
			<ul>
        <li>unilaterally terminate the provision of services under this Agreement in case of violation by the Buyer of the terms of this Agreement.</li>
			</ul>`,
      warrantyTermsTitle5: "RIGHTS AND OBLIGATIONS OF THE BUYER",
      warrantyTermsText5: `5.1. The Buyer is obliged to:
				<ul>
					<li>timely pay and receive the order on the terms of this Agreement;</li>
      	  <li>familiarize themselves with the information about the goods posted on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>upon receipt of the Goods from the person who delivered them, verify the integrity and completeness of the Goods by inspecting the contents of the package. In case of damage or incomplete completeness of the Goods, record them in the act, which, together with the Buyer, must be signed by the person who delivered it to the Buyer.</li>
				</ul>
5.2. The Buyer has the right to:
				<ul>
        	<li>place an order on the corresponding page of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>require the Seller to fulfill the terms of this Agreement;</li>
				</ul>
				`,
      warrantyTermsTitle6: "ORDERING PROCEDURE",
      warrantyTermsText6: `6.1. The Buyer independently places an order on the corresponding page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website by adding the Goods to the virtual basket by clicking the "Add to Cart!" button, or by placing an order by e-mail or by calling the phone number indicated in the contacts section of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website
6.2. The term for the formation of the Order is up to 2 working days from the date of its execution. If the order is sent on a weekend or holiday, the formation period starts from the first working day after the weekend.`,
      warrantyTermsTitle7: "CONTRACT PRICE AND PAYMENT PROCEDURE",
      warrantyTermsText7: `7.1 The price of each individual Goods is determined by the Seller and is indicated on the corresponding page of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website. The price of the Agreement is determined by adding the prices of all selected Goods that are in the virtual basket and the delivery price, which is determined depending on the delivery method in accordance with the terms of Section 8 of this Agreement.
7.2. The cost of the Order may vary depending on the price, quantity or range of goods.
7.3. The buyer can pay for the order in the following ways:
				<ol>
					<li>by bank transfer of money to the Seller's current account specified in the invoice, including through Internet banking (the Buyer pays for the order within three days from the date of receipt of the invoice in the amount of 100% prepayment);</li>
					<li>by cash on delivery upon receipt of the Order at the representative office of the delivery service on the territory of Ukraine or on the territory of another country in accordance with the place of ordering the goods;</li>
					<li>in any other way as agreed with the Seller.</li>
				</ol>
				`,
      warrantyTermsTitle8: "TERMS OF DELIVERY OF GOODS",
      warrantyTermsText8: `8.1. The Buyer receives the Goods by delivery or receives them personally. The procedure for payment and receipt is indicated on the corresponding page of the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>
8.2. When delivering the Goods to other cities of Ukraine or on the territory of another country, performed by other Delivery Services (hereinafter referred to as Carrier Companies), the Buyer fully and unconditionally agrees to the Rules of carriage of goods by these carrier companies.
8.3. The fact of receipt of the Goods and the absence of claims to the quality of the Goods delivered by the Carrier Companies shall be confirmed by the Buyer with his own signature in the consignment note, the declaration of the Carrier Company, or in the expenditure invoice upon receipt of the Goods. For its part, the Seller guarantees the shipment of the Goods to the Carrier Company in the quantity specified and paid for by the Buyer, in a complete set according to the specification of the Goods and in proper (working) condition and quality.
8.4. In case of the Buyer's absence at the delivery address specified by the Buyer in the application or the Buyer's refusal to receive the Goods for unreasonable reasons, upon delivery by the Courier of the carrier company, the Goods shall be returned to the shipping center. Payment for the services of the Carrier Company shall be deducted from the amount paid by the Buyer for the Goods. The balance of the amount shall be returned to the Buyer on the basis of his letter sent to the e-mail: <a href="mailto:info@apulse.com.ua">info@apulse.com.ua</a> indicating the current account to which the funds should be returned. All questions arising in the process of payment and receipt of the Goods can be clarified by the Buyer using the contact information in the Contacts section.`,
      warrantyTermsTitle9: "TERMS OF RETURN OF GOODS",
      warrantyTermsText9: `9.1. In accordance with Art. 9 of the Law of Ukraine "On Consumer Protection", the Buyer has the right to exchange the Goods of good quality within fourteen days, not counting the day of purchase, unless a longer period is announced by the Seller. The Buyer has the right to exchange the Goods, subject to the provisions of the law on the grounds and the list of Goods that are not subject to exchange (return).
9.2. If the Buyer intends to return the Goods, such return shall be made in accordance with the section of the Site "Returns", taking into account the terms and conditions of the carrier or courier in force on the territory of Ukraine or on the territory of another country in accordance with the place of receipt of the Goods.
9.3. In the event of at least one of the listed defects, the Buyer is obliged to record it in a free-form act. The act must be signed by the Buyer and the person who delivered the Goods or the Seller. If possible, defects should be recorded by means of photography or video. Within 1 (one) day, the Buyer is obliged to notify the manager (the Seller's representative responsible for placing the order for the Goods) of the identified defects and agree to replace the Goods, while filling out a complaint form for the return of the Goods on the website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>`,
      warrantyTermsTitle10: "LIABILITY OF THE PARTIES AND DISPUTE RESOLUTION",
      warrantyTermsText10: `10.1. The Parties shall be liable for non-fulfillment or improper fulfillment of the terms of this Agreement in the manner prescribed by this Agreement and applicable international and Ukrainian law.
10.2. In the event of disputes related to the Parties' performance of this Agreement, except for disputes on debt collection from the Buyer, the Parties undertake to resolve them through negotiations in compliance with the claim procedure. The term for consideration of the claim is 7 (seven) calendar days from the date of its receipt. For disputes in connection with debt collection from the Buyer, compliance with the claim procedure is not required.
10.3. All disputes, disagreements or claims arising out of or in connection with this Agreement, including those related to its performance, breach, termination or invalidity, shall be resolved in the appropriate court in accordance with international and Ukrainian substantive and procedural law.`,
      warrantyTermsTitle11: "FORCE MAJEURE CIRCUMSTANCES",
      warrantyTermsText11: `11.1. The Parties shall not be liable for failure to fulfill any of their obligations, except for payment obligations, if they prove that such failure was caused by force majeure, i.e. events or circumstances that are really beyond the control of such party, which occurred after the conclusion of this Agreement, are unpredictable and inevitable.
        The force majeure circumstances include, in particular, natural disasters, strikes, fires, floods, explosions, icing, wars (both declared and undeclared), riots, loss of goods, delays of carriers caused by accidents or adverse weather conditions, dangers and accidents at sea, embargoes, disasters, restrictions imposed by governmental authorities (including allocations, priorities, official requirements, quotas and price controls), if these circumstances directly affect the performance of this Agreement.
11.2. The Party for which it became impossible to fulfill its obligations under this Agreement due to the occurrence of force majeure shall immediately inform the other Party in writing of the occurrence of the above circumstances, as well as provide the other Party with confirmation of force majeure within 30 (thirty) calendar days. Such confirmation will be a certificate, certificate or other relevant document issued by an authorized state body located at the place of occurrence of force majeure.
11.3. The time required by the Parties to fulfill their obligations under this Agreement shall be extended for any period during which the performance was postponed due to the above circumstances.
11.4. If, due to force majeure circumstances, the failure to fulfill obligations under this Agreement continues for more than three months, each of the Parties shall have the right to terminate this Agreement unilaterally by notifying the other Party in writing. Notwithstanding the occurrence of force majeure, before termination of this Agreement due to force majeure, the Parties shall make final settlements.`,
      warrantyTermsTitle12: "OTHER TERMS AND CONDITIONS",
      warrantyTermsText12: `12.1. The information provided by the Buyer is confidential. Information about the Buyer is used solely for the purpose of fulfilling his Order (sending a message to the seller about the order of the Goods, sending advertising messages, etc.)
12.2 By own acceptance of the Agreement or registration on the Website <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> (filling out the registration form), the Buyer voluntarily agrees to the collection and processing of his personal data for the following purposes: the data that becomes known will be used for commercial purposes, including for processing orders for the purchase of goods, receiving information about the order, sending advertising and special offers, information about promotions, sales or any other information about the activities of the <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> Website by telecommunication means (e-mail, mobile communication). For the purposes provided for in this clause, the Buyer has the right to send letters, messages and materials to the postal address, e-mail of the Buyer, as well as send SMS messages, make calls to the phone number specified in the questionnaire.
12.3. The buyer gives the right to process his personal data, including: to place personal data in databases (without additional notice), to carry out lifelong storage of data, their accumulation, updating, changing (as necessary). The Seller undertakes to ensure the protection of data from unauthorized access by third parties, not to distribute or transfer data to any third party (except for the transfer of data to related parties, commercial partners, persons authorized by the Seller to directly process data for the specified purposes, as well as at the mandatory request of the competent state authority).
12.4. In case of unwillingness to receive the newsletter, the Buyer has the right to contact the Seller by writing a statement of refusal to receive advertising materials, sending it to the postal or e-mail address.
12.5. The Seller is not responsible for the content and accuracy of the information provided by the Buyer when placing an order. The Buyer is responsible for the accuracy of the information provided when placing the order.`,
    },
  },
  uk: {
    translation: {
      days: "днів",
      hours: "годин",
      minutes: "хвилин",
      seconds: "секунд",
      promoFrom: "Акція діє з",
      promoTo: "до",
      left: "залишилось",
      leftToEnd: "До кінця акції",
      finished: "завершена",
      promoFinished: "Завершена",
      day1: "день",
      day2: "дні",
      day3: "днів",
      promoDetails: "Детальні умови акції",
      emptyStateTitle: "Список порожній",
      emptyStateDesc:
        "На жаль, наразі немає доступних акцій. Будь ласка, спробуйте пізніше або зв'яжіться з нашою службою підтримки для отримання додаткової інформації.",
      productsCatalog: "Каталог товарів",
      catalog: "Каталог",
      news: "Новини",
      warrantyConditions: "Договір публічної оферти",
      privacyPolicy: "Політика конфіденційності",
      privacyPolicyText: `
			<p>Denis Matiushenko є власником та створив додаток "Apulse" як додаток безкоштовний. Ця ПОСЛУГА надається Denis Matiushenko безкоштовно та призначена для використання як є.
				
				Ця сторінка використовується для інформування відвідувачів про нашу політику щодо збору, використання та розкриття Особової інформації, якщо хтось вирішив використати нашу Службу.
				
				Якщо ви вирішите використовувати наш Сервіс, ви погоджуєтесь на збирання та використання інформації у зв'язку з цією політикою. Особиста інформація, яку Ми збираємо, використовується для надання та покращення Сервісу. ми не будемо використовувати або передавати вашу інформацію будь-кому, крім випадків, описаних у цій Політиці конфіденційності.
				
				Терміни, що використовуються у цій Політиці конфіденційності, мають ті ж значення, що й у наших Умовах та положеннях, які доступні за адресою, якщо інше не визначено у цій Політиці конфіденційності.</p>
			<h3>Збір та використання інформації</h3>
			<p>Для кращого досвіду при використанні нашого Сервісу ми можемо вимагати від вас надати нам певну особисту інформацію[додайте все, що ви збираєте тут, наприклад, ім'я користувача, адресу, місцезнаходження, зображення]. Інформація, яку ми запит буде [зберігатися на вашому пристрої і не буде збиратися нами будь-яким чином.
				
				Додаток використовує інші сервіси, які можуть збирати інформацію, що використовується для вашої ідентифікації.
			
				Посилання на політику конфіденційності сторонніх постачальників послуг, які використовуються програмою</p>
				<ul>
					<li>Сервіси Google Play</li>
					<li>Firebase Crashlytics</li>
					<li>Дані журналу</li>
				</ul>
				<p>Ми хочемо повідомити вам, що кожного разу, коли ви використовуєте нашу Службу, у разі помилки в додатку ми збираємо дані та інформацію (через інші продукти) на вашому телефоні під назвою Log Дані. Ці дані журналу можуть включати таку інформацію, як адреса інтернет-протоколу («IP») вашого пристрою, ім'я пристрою, версія операційної системи, конфігурація програми при використанні [мого/нашого] Сервісу, час і дата використання Вами Сервісу. , та інша статистика.</p>
			<h3>Cookies</h3>
			<p>Файли cookie — це файли з невеликим обсягом даних, які зазвичай використовуються як анонімні унікальні ідентифікатори. Вони надсилаються до вашого браузера з веб-сайтів, які ви відвідуєте, і зберігаються у внутрішній пам'яті пристрою.

			Ця Служба не використовує ці «куки» у явному вигляді. Однак програма може використовувати сторонній код та бібліотеки, які використовують файли cookie для збирання інформації та покращення своїх послуг. У вас є можливість прийняти або відмовитися від цих файлів cookie і дізнатися, коли файл cookie відправляється на ваш пристрій. Якщо ви вирішите відмовитися від наших файлів cookie, ви не зможете використовувати деякі частини цієї послуги.</p>
			<h3>Постачальники послуг</h3>
			<p>[Я/ми] можемо наймати сторонні компанії та приватні особи з таких причин:</p>
			<ul>
				<li>Для полегшення Сервісу;</li>
				<li>Для надання Сервісу від нашого імені;</li>
				<li>Для надання послуг, пов'язаних із Сервісом; або</li>
				<li>Щоб допомогти нам проаналізувати, як використовується наш сервіс.</li>
				<li>[Я/ми] хочемо повідомити користувачам цієї Послуги, що ці треті сторони мають доступ до їх Особистої інформації. Причина полягає в тому, щоб виконувати покладені на них завдання від нашого імені. Однак вони зобов'язані не розголошувати і не використовувати інформацію для будь-яких інших цілей.</li>
			</ul>
			<h3>Безпека</h3>
			<p>[Я/ми] цінуємо вашу довіру у наданні нам вашої Особистої інформації, тому ми прагнемо використовувати комерційно прийнятні засоби її захисту. Але пам'ятайте, що жоден метод передачі через Інтернет або метод електронного зберігання не є 100% безпечним і надійним, і [Я/Ми] не можемо гарантувати його абсолютну безпеку.</p>
			<h3>Посилання на інші сайти</h3>
			<p>Ця служба може містити посилання на інші сайти. Якщо ви натиснете на інше посилання, ви будете перенаправлені на цей сайт. Зауважте, що ці зовнішні сайти не управляються [я/нас]. Тому [я/ми] рекомендую вам ознайомитися з Політикою конфіденційності цих веб-сайтів. [Я/ми] не контролюємо та не несемо відповідальності за зміст, політику конфіденційності чи дії будь-яких сторонніх сайтів чи служб.</p>
			<h3>Конфіденційність дітей</h3>
			<p>Ці Послуги не призначені для осіб віком до 13 років. ми не збираємо навмисно особисту інформацію від дітей віком до 13 років. У випадку, якщо ми виявлю, що дитина молодше 13 років надала нам особисту інформацію, ми негайно видалимо її з наших серверів. Якщо ви є батьком або опікуном і знаєте, що ваша дитина надала нам особисту інформацію, будь ласка, зв'яжіться зі мною/ми, щоб [я/ми] могли вжити необхідних дій.</p>
			<h3>Зміни цієї Політики конфіденційності</h3>
			<p>[Я/ми] можемо час від часу оновлювати нашу політику конфіденційності. Таким чином, вам рекомендується періодично переглядати цю сторінку щодо будь-яких змін. [Я/ми] повідомимо вас про будь-які зміни, опублікувавши нову Політику конфіденційності на цій сторінці.
			
			Ця політика діє з 20 березня 2023 року.</p>
			<h3>Зв'язатися з нами</h3>
			<p>Якщо у вас є питання або пропозиції щодо [моєї/нашої] Політики конфіденційності, не соромтеся звертатися до [мені/нам] за адресою .</p>
			`,
      aboutUs: "Про нас",
      support: "Підтримка",
      contacts: "Контакти",
      contactUs: "Зв'язатися з нами",
      search: "Пошук",
      openTgBot: "Відкрити телеграм бот",
      info: "Інформація",
      allRightsReserved: "Всі права захищені",
      followUsOnSocial: "Завантажити наш додаток",
      promotions: "Акції",
      promo: "Акція",
      pair: "В парі",
      popularModels: "Популярні моделі",
      sale: "Розпродаж",
      allProducts: "Всі товари",
      newsAndArticles: "Новини та статті",
      sortBy: "Сотрувати за",
      ASC: "Зростанням",
      DESC: "Спаданням",
      promoASC: "Aкцією за зростанням",
      promoDESC: "Aкцією за спаданням",
      select: "Оберіть",
      resetFilters: "Скинути фільтри",
      apply: "Застосувати",
      price: "Ціна",
      available: "В наявності",
      notAvailable: "Немає в наявності",
      alreadyInCart: "Товар вже у кошику",
      aviableNotify: "Отримати сповіщення про наявність товару",
      alreadyNotify:
        "Ми надішлемо сповіщення, як тільки товар буде в наявності",
      productCode: "Код товару",
      buy: "Купити",
      characteristics: "Характеристики",
      description: "Опис",
      reviews: "Відгуки",
      writeReview: "Написати відгук",
      leaveFeedback: "Залишити відгук",
      name: "Ім'я",
      surname: "Призвіще",
      email: "Email",
      mark: "Оцінка",
      message: "Повідомлення",
      showInstruction: "Показати інструкцію",
      minimalCount: "Мінімальна кількість замовлення цього товару",
      noReviews: "Для цього продукту немає відгуків. Ви можете бути першим!",
      goToHomePage: "Перейти на головну сторінку",
      title404: "Сторінку не знайдено",
      message404: "На жаль, ми не можемо знайти сторінку, яку ви шукаєте.",
      queryResult: "Результати за запитом",
      queryNoResult: "Немає результатів за запитом",
      noResultMessage1:
        "На жаль, ми не знайшли жодного товару, що відповідає вашому запиту.",
      noResultMessage2: "Будь ласка, спробуйте змінити параметри пошуку.",
      searchMinLengthError:
        "Пошуковий запит повинен мати довжину не менше 2 символів.",
      login: "Увійти",
      register: "Зареєструватися",
      forgotPass: "Забули пароль",
      pass: "Пароль",
      emailOrPhone: "Email або телефон у форматі +380",
      continue: "Продовжити",
      phone: "Телефон",
      confirmPass: "Підтвердіть пароль",
      country: "Країна",
      region: "Область",
      terms: "Я прочитав і погоджуюсь з ",
      termsWord: "політикою конфіденційності",
      passwordnotSame: "Паролі не однакові",
      passwordLength: "Пароль повинен містити принаймні 6 символів",
      company: "Компанія",
      yourName: "Ваше ім'я",
      yourSurname: "Ваше призвіще",
      enterCompany: "Введіть назву компанії",
      enter: "Введіть",
      error: "Помилка!",
      chooseCountry: "Будь ласка, оберіть країну",
      chooseCity: "Будь ласка, оберіть  місто",
      chooseWarehouse: "Будь ласка, оберіть  відділення Нової Пошти",
      chooseState: "Будь ласка, оберіть  область",
      invalidPhone: "Невірний формат номера телефону",
      confirm: "Підтвердити",
      confirmCodeDescr:
        "Будь ласка, введіть код перевірки, відправлений на ваш номер телефону.",
      confirmCodeDescrEmail:
        "Будь ласка, введіть код перевірки, відправлений на ваш email.",
      confirmEmail: "Будь ласка, підвердіть ваш email.",
      confirmEmailText1:
        "Ми відправили вам лист з посиланням для підтвердження. Будь ласка, перейдіть за посиланням, щоб підтвердити email.",
      confirmEmailText2:
        "Якщо ви не отримали листа, перевірте папку 'Спам' або зверніться до нашої служби підтримки.",
      enterCode: "Ваш код перевірки",
      phoneCode: "Код країни",
      success: "Успіх!",
      notEmailOrPhone:
        "На жаль, ви ввели невірний формат електронної адреси або номера телефону. Будь ласка, перевірте введені дані та спробуйте ще раз.",
      resendCode: "Надіслати код знову",
      maxResendCount: "Максимальна кількість повторних відправлень перевищена.",
      codeAgainSend:
        "Код успішно перевідправлено! Перевірте свій телефон, щоб отримати новий код підтвердження.",
      codeAgainSendEmail:
        "Код успішно перевідправлено! Перевірте свій email, щоб отримати новий код підтвердження.",
      profile: "Профіль",
      favorites: "Обрані товари",
      cart: "Кошик",
      logOut: "Вийти",
      agreeLogout: "Ви впевнені, що хочете вийти з аккаунту?",
      agreeDelete: "Ви впевнені, що хочете видалити всі товари з кошика?",
      agreeClearAllMessages: "Бажаєте зробити всі повідомлення прочитаними?",
      agreeDeleteOne: "Ви впевнені, що хочете видалити товар з кошика?",
      yes: "Так",
      no: "Ні",
      loginOrRegisterTitle: "Увійдіть до аккаунту щоб продовжити",
      loginOrRegister: "Увійти або зареєструватися",
      quantity: "Кількість",
      remove: "Видалити",
      send: "Відправити",
      setArticleRating:
        "Допоможіть нам поліпшити контент, поставивши оцінку цій новині.",
      emptyFavorites: "У вас ще немає товарів у списку улюблених.",
      emptyFavorites2:
        "Знайдіть товар, який вам подобається, та додайте його до улюблених, щоб мати швидкий доступ до нього.",
      successAddedFavorite: "Товар успішно додано до обраних товарів.",
      successAddedWaiting: "Товар успішно додано до списку очікування.",
      successDeleteFavorite: "Товар успішно видалено з обраних товарів.",
      successDeleteWaiting: "Товар успішно видалено зі списку очікування.",
      successAddedToOrder: "Товар успішно додано до кошику.",
      attention: "Увага",
      afterRegisterText:
        "З вами зв'яжуться протягом двох робочих днів для уточнення інформації.",
      ok: "Добре",
      reviewMessage: "Напишіть свій відгук тут...",
      reviewSent: "Дякуємо за ваш відгук!",
      reviewSentInfo: "Після перевірки модератором він буде опублікований.",
      cancel: "Скасувати",
      orders: "Мої замовлення",
      clearCart: "Очистити кошик",
      toOrdering: "Замовити",
      emptyCart1: "У вас ще немає товарів у кошику.",
      emptyCart2:
        "Оберіть товари, які вам сподобалися, та додайте їх до кошика.",
      retailPrice: "Роздрібна ціна",
      wholesalePrice: "Оптова ціна",
      largeWholesalePrice: "Партнер",
      partnerPrice: "Партнерська ціна",
      specialPrice: "Спеціальна ціна",
      total: "Всього",
      promoCode: "Промокод",
      emptyOrders1: "У вас ще немає замовлень.",
      emptyOrders2: "Зробіть перше замовлення, щоб побачити його тут.",
      emptyClosedOrders: "У вас ще немає закритих замовлень.",
      pending: "На розгляді",
      closed: "Закрито",
      piece: "шт.",
      status: "Статус",
      comment: "Коментар",
      yourComment: "Ваш коментар",
      yourMessage: "Ваше повідомлення",
      yourAddress: "Ваша адреса",
      writeUs: "Написати нам",
      discount: "Знижка",
      paymentMethod: "Спосіб оплати",
      fact: "Фактичний",
      nonFact: "Не фактичний",
      workHour: "Режим роботи",
      messagesIsRead: "Всі повідомлення прочитано!",
      workHourVal: "Пн-Сб: 9:00-21:00, Нд: вихідний.",
      saleDep: "Відділ продажів",
      techDep: "Технічна підтримка",
      orderType: "Спосіб доставки",
      delivery: "Доставка кур'єром Нової Пошти",
      pickup: "Самовивіз (м. Харків)",
      deliveryToWarehouse: "Доставка до відділення Нової Пошти",
      city: "Місто",
      state: "Область",
      warehouse: "Відділення",
      address: "Адреса",
      processOrder: "Оформити замовлення",
      factPay: "Оплата за фактом (без відстрочки платежу). Дає знижку - 5%",
      chooseOrderType: "Оберіть спосіб доставки",
      warehouseNP: "До відділення Нової Пошти",
      courierNP: "Кур'єром Нової Пошти",
      pickupAddress:
        'м. Харків, Ващенківський провулок 28Б, 1 поверх, магазин "Автозвук"',
      address2: "м. Харків, провулок Ващенківський, 28 «Б»",
      pickupText1: "Після оформлення замовлення",
      pickupStrongText: "відмічені товари бронюються за Вами на термін 3 дні.",
      pickupText2:
        "По закінченню терміну, якщо товар не реалізований, бронь знімається і товар може бути проданий іншому покупцеві.",
      totalSum: "Загальна сума",
      addedToOrder: "У кошику",
      currentOrders: "Поточні замовлення",
      historyOrders: "Історія замовлень",
      thanksForOrder: "Дякуємо за замовлення!",
      order: "Замовлення",
      orderInfo:
        "З вами зв'яжеться наш менеджер для уточнення деталей замовлення.",
      uah: "грн",
      грн: "грн",
      ye: "y.o.",
      change: "Змінити",
      noChange: "Жодні дані не змінено",
      notifications: "Повідомлення",
      emptyNotifications: "У вас ще немає повідомлень.",
      emptyNotifyProducts: "Товари ще не були додані до списку для сповіщень.",
      developed: "Розроблено",
      applied: "застосований",
      notifyTitle: "Список очікування",
      downloadPriceList: "Завантажити прайс-лист",
      downloadShortPriceList: "Завантажити скорочений прайс-лист",
      downloadXML: "Завантажити XML файл",
      guiideByChoice: "Гід по вибору",
      waitTwoMinutes: "Очікуйте, завантаження може тривати близько 2 хвилин",
      successConfirmEmail: "Ви успішно підтвердили свою електронну адресу!",
      failConfirmEmail: "На жаль, не вдалося підтвердити електрону адресу.",
      storage: "Cклад",
      availability: "Наявність",
      allWarehouses: "На всіх складах",
      toOrder: "Під замовлення 2-3 дні",
      noResultByFilter:
        "На жаль, ми не знайшли відповідні товари за обраним фільтром.",
      errorAuth: "Щось пішло не так. Зверніться в службу підтримки.",

      this_phone_number_is_already_in_use:
        "Цей номер телефону вже використовується",
      this_email_is_already_in_use:
        "Ця адреса електронної пошти вже використовується",
      this_email_already_in_use:
        "Ця адреса електронної пошти вже використовується",
      today_max_phone_code_amount_expired:
        "Перевищено ліміт кількості запитів на коди на сьогодні",
      code_is_expite_or_wrong: "Код прострочений або введений неправильно",
      login_or_password_wrong: "Невірний логін або пароль",
      user_not_found: "Користувача не знайдено",
      please_confirm_your_phone: "Будь ласка, підтвердьте свій номер телефону.",
      promo_code_not_found:
        "Промокод не знайдено. Будь ласка, перевірте правильність введеного коду та спробуйте ще раз.",
      promo_code_expired:
        "Упс! Промокод, який ви намагаєтеся використати, вже закінчився.",
      promo_code_usage_amount_exhausted:
        "На жаль, кількість використань даного промокоду вичерпано. Будь ласка, спробуйте використати інший промокод.",
      you_already_use_this_promo_code: "Ви вже використали цей промокод.",
      promo_code_start_date_has_not_yet_arrived:
        "Дата початку дії промокоду ще не настала.",
      warrantyTermsTitle0: "Договір публічної оферти",
      warrantyTermsText0: `За цим Договором одна сторона Продавець з одного боку, і будь-яка особа, яка прийняла умови цього Договору публічної оферти - Покупець, з іншого боку, надалі разом іменовані - Сторони, уклали цей Договір публічної оферти (далі - Договір), адресований необмеженому колу осіб, що є офіційною публічною пропозицією Продавця укласти з Покупцями договір купівлі-продажу Товарів, фотографії яких розміщені у відповідному розділі Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">https://apulse.com.ua/</a>.
Продавці, що мають намір здійснювати продаж Товарів за допомогою Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> і Покупці при придбанні Товарів, зображення яких розміщені на відповідних сторінках <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, приймають умови цього Договору про наступне.`,
      warrantyTermsTitle1: "ЗАГАЛЬНІ ПОЛОЖЕННЯ",
      warrantyTermsText1: `Договірні відносини між Продавцем і Покупцем оформляються у вигляді Договору публічної оферти. Натискання на сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> у відповідному розділі кнопки «ЗАМОВИТИ» означає, що Покупець, незалежно від статусу (фізична особа, юридична особа, фізична особа-підприємець), згідно з чинним міжнародним та українським законодавством , взяв до виконання умови Договору публічної оферти, які вказані нижче.
1.2. Договір публічної оферти є публічним, тобто відповідно до статей 633, 641 Цивільного кодексу України його умови однакові для всіх Покупців незалежно від статусу (фізична особа, юридична особа, фізична особа-підприємець). При повній згоді з цим Договором Покупець приймає умови і порядок оформлення замовлення, оплати та доставки товару Продавцем, відповідальності за недобросовісне Замовлення і за невиконання умов цього Договору.
1.3. Цей Договір набуває чинності з моменту натискання на кнопку «ЗАМОВИТИ», яким Покупець дає згоду здійснити покупку наявного у Продавця Товару і діє до моменту отримання Покупцем Товару від Продавця і повного розрахунку з ним.
1.4. Для регулювання договірних правовідносин за Договором Сторони вибирають і, в разі необхідності, застосовують українське законодавство. Якщо міжнародним договором, згода на обов'язковість якого надана Верховною Радою України, встановлено інші правила, ніж ті, які встановлені українським законодавством, то застосовуються правила міжнародного договору.`,
      warrantyTermsTitle2: "ТЕРМІНИ ТА ВИЗНАЧЕННЯ",
      warrantyTermsText2: `«Договір публічної оферти» - публічний договір, зразок якого розміщено на Веб- <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> и застосування якого є обов'язковим для всіх Продавців, що містить пропозицію Продавця про придбання Товару, зображення якого розміщено на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, спрямоване невизначеному колу осіб, у тому числі Покупцям.
«Акцепт» - прийняття Покупцем пропозиції продавця про придбання Товару, зображення якого розміщено на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, шляхом додавання його в віртуальну корзину і відправки Замовлення.
«Товар» - предмет торгівлі (виріб, модель, аксесуар, комплектуючі та супутні предмети, будь-які інші предмети торгівлі), про придбання якого на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> розміщено пропозицію продавця.
«Покупець» - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, згідно з чинним міжнародним та українським законодавством, які відвідали Веб-сайт <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> і мають намір придбати той чи інший Товар.
«Продавець» - будь-яка дієздатна фізична, юридична особа, фізична особа-підприємець, згідно з чинним міжнародним та українським законодавством, які є власниками або розповсюджувачами Товару і за допомогою Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> мають намір його продати.
«Замовлення» - належним чином оформлена і розміщена на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> заявка Покупця на купівлю Товарів, адресующаяся Продавцю.
«Законодавство» - встановлені українським або міжнародним законодавством норми для регулювання договірних правовідносин за Договором.
«Істотний недолік Товару» - недолік, який робить неможливим чи недопустимим використання Товару відповідно до його цільового призначення, виник з вини виробника (Продавця), після його усунення проявляється знову з незалежних від споживача причин.`,
      warrantyTermsTitle3: "ПРЕДМЕТ ДОГОВОРУ",
      warrantyTermsText3: `3.1. Продавець зобов'язується на умовах та в порядку, визначених цим Договором продати Товар на основі Замовлення, оформленого Покупцем на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, а Покупець зобов'язується на умовах та в порядку, визначених цим Договором, купити товар і сплатити за нього гроші.
3.2. Продавець гарантує, що Товар не переданий в заставу, не є предметом спору, не перебуває під арештом, а також на нього відсутні будь-які права третіх осіб.
3.3. Продавець і Покупець підтверджують, що чинний Договір не є фіктивною або удаваним Договором або Договором, укладеним під впливом тиску або обману.
3.4. Продавець підтверджує, що має всі необхідні дозволи на здійснення господарської діяльності, які регулюють сферу правовідносин, що виникають і діють в процесі виконання цього Договору, а також гарантує, що має право на виробництво та / або реалізацію товару без будь-яких обмежень, відповідно до вимогами чинного законодавства України, і зобов'язується нести відповідальність в разі порушення прав Покупця в процесі виконання цього Договору та реалізації Товару.`,
      warrantyTermsTitle4: "ПРАВА ТА ОБОВ'ЯЗКИ ПРОДАВЦЯ",
      warrantyTermsText4: `4.1. Продавець зобов'язаний:
				<ul>
					<li>виконувати умови цього Договору</li>
					<li>виконувати замовлення Покупця в разі надходження оплати від Покупця;</li>
					<li>передати Покупцю Товар відповідно до обраного зразком на відповідній сторінці Веб-сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, оформленим замовленням і умов цього Договору;</li>
					<li>перевірити якісні та кількісні характеристики Товару під час його упаковки на складі;</li>
				</ul>
4.2. Продавець має право:
				<ul>
					<li>в односторонньому порядку припинити надання послуг за цим Договором у разі порушення Покупцем умов цього Договору.</li>
				</ul>`,
      warrantyTermsTitle5: "ПРАВА ТА ОБОВ'ЯЗКИ ПОКУПЦЯ",
      warrantyTermsText5: `5.1. Покупець зобов'язаний:
        <ul>
					<li>своєчасно оплатити та отримати замовлення на умовах цього Договору;</li>
        	<li>ознайомитися з інформацією про товар, розміщеної на Веб-сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>при отриманні Товару у особи, яка вчинила його доставку, переконатися в цілісності і комплектності Товару шляхом огляду вмісту упаковки. У разі пошкодження або неповної комплектації Товару - зафіксувати їх в акті, який разом з Покупцем має підписати особа, яка здійснила його доставку Покупцеві.</li>
				</ul>
5.2. Покупець має право:
			<ul>
				<li>оформити замовлення на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        <li>вимагати від продавця виконання умов цього Договору;</li>
			</ul>`,
      warrantyTermsTitle6: "ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ",
      warrantyTermsText6: `6.1. Покупець самостійно оформляє замовлення на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> шляхом додавання Товарів в віртуальну корзину за допомогою натискання кнопки «В кошик!», Або зробивши замовлення по електронній пошті, або за номером телефону, зазначеному в розділі контактів Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>
6.2. Термін формування Замовлення до 2 робочих днів з моменту його оформлення. У разі, якщо замовлення відправлений у вихідний або святковий день, термін формування починається з першого після вихідного робочого дня.`,
      warrantyTermsTitle7: "ЦІНА ДОГОВОРУ І ПОРЯДОК ОПЛАТИ",
      warrantyTermsText7: `7.1. Ціна кожного окремого Товару визначається Продавцем і вказується на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> Ціна Договору визначається шляхом додавання цін всіх обраних Товарів, які перебувають у віртуальній корзині і ціни доставки, яка визначається в залежності від способу доставки відповідно до умов розділу 8 цього Договору.
7.2. Вартість Замовлення може змінюватися в залежності від ціни, кількості або номенклатури товару.
7.3. Покупець може оплатити замовлення наступними способами:
        <ol>
				<li>за допомогою банківського переказу грошей на поточний рахунок Продавця, вказаний в рахунку, в т.ч. за допомогою Інтернет-банкінгу (Покупець оплачує замовлення протягом трьох днів з дати отримання рахунку в розмірі 100% передоплати);</li>
        <li>післяплатою при отриманні Замовлення в представництві служби доставки на території України або на території іншої країни відповідно до місця здійснення замовлення товару;</li>
        <li>будь-яким іншим способом за домовленістю з Продавцем.</li>
				</ol>`,
      warrantyTermsTitle8: "УМОВИ ДОСТАВКИ ТОВАРУ",
      warrantyTermsText8: `8.1. Покупець отримує Товар за допомогою доставки, або отримує його особисто. Порядок оплати і отримання зазначений на відповідній сторінці Веб-сайту <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>
8.2. При доставці Товарів в інші міста України або на території іншої країни, виконуваної іншими Службами доставки (далі Компаніями-перевізниками) Покупець в повному обсязі і беззастережно погоджується з Правилами перевезення вантажів цими компаніями-перевізниками.
8.3. Факт отримання Товару та відсутність претензій до якості Товару, що доставляється Компаніями-перевізниками, Покупець підтверджує власним підписом в товаро-транспортної накладної, декларації Компанії-перевізника, або у видатковій накладній при отриманні Товару. Зі свого боку, Продавець гарантує відвантаження Товару Компанії-перевізнику в кількості, зазначеній і сплаченому Покупцем, в комплектності згідно специфікації цього Товару та в належному (робочому) стані і якості.
8.4. У разі відсутності Покупця за адресою доставки, зазначеної Покупцем в заявці або відмови Покупця від отримання Товару по необґрунтованих причин, при доставці Кур'єром компанії-перевізника, Товар повертається в торговий центр відвантаження. Оплата за послуги Компанії-перевізника віднімається від суми, перерахованої Покупцем за Товар. Залишок суми повертається Покупцю на підставі його листа, відправленого на e-mail: <a href="mailto:info@apulse.com.ua">info@apulse.com.ua</a> із зазначенням розрахункового рахунку, на який повинні бути повернуті кошти. Всі питання, що виникли в процесі оплати та отримання Товару, Покупець може з'ясувати по контактними даними в розділі Контакти.`,
      warrantyTermsTitle9: "УМОВИ ПОВЕРНЕННЯ ТОВАРУ",
      warrantyTermsText9: `9.1. Відповідно до ст. 9 Закону України «Про захист прав споживачів», Покупець має право на обмін Товару належної якості протягом чотирнадцяти днів, не рахуючи дня покупки, якщо триваліший строк не оголошений Продавцем. Покупець має право на обмін Товару з урахуванням положень законодавства про підстави і переліку Товарів, які не підлягають обміну (поверненню).
9.2. Якщо Покупець має намір повернути Товар, таке повернення здійснюється відповідно до розділу Сайту «Повернення» з урахуванням правил і умов перевізника або кур'єра, які діють на території України або на території іншої країни відповідно до місця скоєння отримання Товару.
9.3. У разі наявності хоча б одного з перерахованих недоліків, Покупець зобов'язаний зафіксувати його в складеному акті довільної форми. Акт повинен бути підписаний Покупцем і особою, яка здійснила доставку Товару або Продавцем. При можливості недоліки повинні бути зафіксовані засобами фото- або відеозйомки. Протягом 1 (одного) дня Покупець зобов'язаний повідомити менеджера (представника Продавця відповідального за оформлення замовлення на Товар) про виявлені недоліки і домовитися про заміну Товару, заповнивши при цьому рекламаційну форму на повернення Товару на сайті <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>`,
      warrantyTermsTitle10: "ВІДПОВІДАЛЬНІСТЬ СТОРІН І ВИРІШЕННЯ СПОРІВ",
      warrantyTermsText10: `10.1. Сторони несуть відповідальність за невиконання або неналежне виконання умов цього Договору в порядку, передбаченому цим Договором та чинним міжнародним та українським законодавством.
10.2. У разі виникнення суперечок, пов'язаних з виконанням Сторонами цього Договору, за винятком спорів про стягнення заборгованості з покупця, Сторони зобов'язуються вирішувати їх шляхом переговорів з дотриманням претензійного порядку. Термін розгляду претензії - 7 (сім) календарних днів з дати її отримання. По спорах у зв'язку зі стягненням заборгованості з Покупця дотримання претензійного порядку не потрібно.
10.3. Усі спори, розбіжності або вимоги, що виникають з цього Договору або в зв'язку з ним, у тому числі що стосуються його виконання, порушення, припинення або недійсності, підлягають вирішенню у відповідному суді відповідно до міжнародного та українського матеріального і процесуального права.`,
      warrantyTermsTitle11: "ФОРС-МАЖОРНІ ОБСТАВИНИ",
      warrantyTermsText11: `11.1. Сторони не несуть відповідальності за невиконання будь-якого зі своїх зобов'язань, за винятком зобов'язань по оплаті, якщо доведуть, що таке невиконання було викликано форс-мажорними обставинами, тобто подіями або обставинами, які дійсно перебувають поза контролем такої сторони, які настали після укладення цього Договору, носять непередбачуваний і невідворотний характер.
До форс-мажорних обставин належать, зокрема, природні катаклізми, страйки, пожежі, повені, вибухи, обмерзання, війни (як оголошені, так і неоголошені), заколоти, загибель товару, затримки перевізників, викликані аваріями або несприятливими погодними умовами, небезпеки і випадковості на морі, ембарго, катастрофи, обмеження, що накладаються державними органами (включаючи розподілу, пріоритети, офіційні вимоги, квоти і ціновий контроль), якщо ці обставини безпосередньо вплинули на виконання цього Договору.
11.2. Сторона, для якої стало неможливим виконання зобов'язань за цим Договором через настання форс-мажорних обставин, повинна негайно інформувати іншу Сторону в письмовому вигляді про виникнення вищевказаних обставин, а також протягом 30 (тридцяти) календарних днів надати іншій Стороні підтвердження форс-мажорних обставин. Таким підтвердженням буде довідка, сертифікат або інший відповідний документ, виданий уповноваженим державним органом, розташованим за місцем виникнення форс-мажорних обставин.
11.3. Час, який потрібен Сторонам для виконання своїх зобов'язань за цим Договором, буде продовжено на будь-який термін, протягом якого було відкладено виконання через перерахованих обставин.
11.4. Якщо через дії обставин непереборної сили невиконання зобов'язань за цим Договором продовжується більше трьох місяців, кожна зі Сторін має право розірвати цей Договір в односторонньому порядку, письмово повідомивши про це іншу сторону. Незважаючи на наступ форс-мажору, перед припиненням цього Договору внаслідок форс-мажорних обставин Сторони здійснюють остаточні взаєморозрахунки.`,
      warrantyTermsTitle12: "ІНШІ УМОВИ",
      warrantyTermsText12: `12.1. Інформація, яку надає Покупцем є конфіденційною. Інформація про Покупця використовується виключно в цілях виконання його Замовлення (відправки повідомлення продавцю про замовлення Товару, відправки рекламних повідомлень і інш.).
12.2. Власним акцептування Договору або реєстрацією на Веб-сайті <a target="_blank" rel="noreferrer" href="https://apulse.com.ua/">apulse.com.ua</a> (заповнення реєстраційної анкети) Покупець добровільно дає згоду на збір і обробку своїх персональних даних з подальшою метою: дані, які стають відомі, будуть використовуватися в комерційних цілях , в тому числі для обробки замовлень на придбання товарів, отримання інформації про замовлення, розсилки телекомунікаційними засобами зв'язку (електронною поштою, мобільним зв'язком) рекламних та спеціальних пропозицій, інформації про акції, роз Гриша або будь-який інший інформації про діяльність Веб-сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> Для цілей, передбачених цим пунктом, Покупцеві мають право направлятися листи, повідомлення та матеріали на поштову адресу, e-mail Покупця, а також відправлятися sms-повідомлення, здійснюватися дзвінки на вказаний в анкеті телефонний номер.
12.3. Покупець дає право здійснювати обробку його персональних даних, в тому числі: поміщати персональні дані в бази даних (без додаткового повідомлення про це), здійснювати довічне зберігання даних, їх накопичення, оновлення, зміна (у міру необхідності). Продавець зобов'язується забезпечити захист даних від несанкціонованого доступу третіх осіб, не поширювати і не передавати дані будь-якої третьої сторони (крім передачі даних пов'язаним особам, комерційним партнерам, особам, уповноваженим Продавцем на здійснення безпосередньої обробки даних для зазначених цілей, а також на обов'язковий запит компетентного державного органу).
12.4. У разі небажання отримувати розсилку, Покупець має право звернутися до Продавця, написавши заяву про відмову від отримання рекламних матеріалів, направивши його на поштову або електронну адресу.
12.5. Продавець не несе відповідальності за зміст і достовірність інформації, наданої Покупцем при оформленні замовлення. Покупець несе відповідальність за достовірність інформації, зазначеної при оформленні замовлення інформації.`,
    },
  },
  ru: {
    translation: {
      days: "дней",
      hours: "часов",
      minutes: "минут",
      seconds: "секунд",
      left: "осталось",
      leftToEnd: "До окончания акции",
      day1: "день",
      day2: "дня",
      day3: "дней",
      finished: "завершено",
      promoDetails: "Детали акции",
      promoFrom: "Акция действует с",
      promoTo: "по",
      promoFinished: "Завершена",
      emptyStateTitle: "Список пуст",
      emptyStateDesc:
        "Извините, в данный момент нет доступных акций. Пожалуйста, попробуйте позже или свяжитесь с поддержкой для получения информации.",
      productsCatalog: "Каталог товаров",
      catalog: "Каталог",
      news: "Новости",
      warrantyConditions: "Договор публичной оферты",
      privacyPolicy: "Политика конфиденциальности",
      privacyPolicyText: `
      <p>Денис Матюшенко является владельцем и создателем приложения «Apulse» как бесплатного приложения. Этот СЕРВИС предоставляется Денисом Матюшенко бесплатно и предназначен для использования «как есть».
      
      Эта страница используется для информирования посетителей о нашей политике в отношении сбора, использования и раскрытия Личной информации, если кто-то решит воспользоваться нашим Сервисом.
      
      Если вы решите использовать наш Сервис, вы соглашаетесь на сбор и использование информации в соответствии с данной политикой. Личная информация, которую мы собираем, используется для предоставления и улучшения Сервиса. Мы не будем использовать или передавать вашу информацию кому-либо, за исключением случаев, описанных в настоящей Политике конфиденциальности.
      
      Термины, используемые в настоящей Политике конфиденциальности, имеют те же значения, что и в наших Условиях и положениях, которые доступны, если не указано иное в настоящей Политике конфиденциальности.</p>
      <h3>Сбор и использование информации</h3>
      <p>Для улучшения опыта использования нашего Сервиса мы можем потребовать от вас предоставить нам определенную личную информацию [добавьте все, что вы собираете, например, имя пользователя, адрес, местоположение, изображения]. Запрашиваемая информация будет [храниться на вашем устройстве и никак не собираться нами].
      
      Приложение использует сторонние сервисы, которые могут собирать информацию, используемую для вашей идентификации.
      
      Ссылки на политики конфиденциальности сторонних сервисов, используемых приложением:</p>
      <ul>
        <li>Google Play Services</li>
        <li>Firebase Crashlytics</li>
        <li>Данные журнала</li>
      </ul>
      <p>Мы хотим сообщить вам, что при каждом использовании нашего Сервиса в случае ошибки в приложении мы собираем данные и информацию (через сторонние продукты) на вашем телефоне, называемые Данными журнала. Эти данные могут включать информацию, такую как IP-адрес вашего устройства, имя устройства, версия операционной системы, конфигурация приложения при использовании нашего Сервиса, время и дата использования Сервиса и другая статистика.</p>
      <h3>Куки</h3>
      <p>Куки — это файлы с небольшим объемом данных, которые обычно используются как анонимные уникальные идентификаторы. Они отправляются в ваш браузер с посещаемых вами веб-сайтов и хранятся во внутренней памяти вашего устройства.
      
      Этот Сервис не использует явно эти «куки». Однако приложение может использовать сторонний код и библиотеки, которые используют куки для сбора информации и улучшения своих услуг. Вы можете принять или отклонить эти куки и получать уведомления, когда куки отправляются на ваше устройство. Если вы решите отклонить наши куки, вы не сможете использовать некоторые части этого Сервиса.</p>
      <h3>Провайдеры услуг</h3>
      <p>[Я/Мы] можем нанимать сторонние компании и частных лиц по следующим причинам:</p>
      <ul>
        <li>Для облегчения нашего Сервиса;</li>
        <li>Для предоставления Сервиса от нашего имени;</li>
        <li>Для выполнения услуг, связанных с Сервисом; или</li>
        <li>Для помощи в анализе использования нашего Сервиса.</li>
        <li>[Я/Мы] хотим проинформировать пользователей этого Сервиса, что эти третьи стороны имеют доступ к их Личной информации. Причина — выполнение задач, порученных им от нашего имени. Однако они обязаны не разглашать и не использовать информацию для других целей.</li>
      </ul>
      <h3>Безопасность</h3>
      <p>[Я/Мы] ценим ваше доверие в предоставлении нам вашей Личной информации, поэтому мы стремимся использовать коммерчески приемлемые средства для ее защиты. Однако помните, что ни один метод передачи через Интернет или метод электронного хранения не является 100% безопасным и надежным, и [я/мы] не можем гарантировать его абсолютную безопасность.</p>
      <h3>Ссылки на другие сайты</h3>
      <p>Этот Сервис может содержать ссылки на другие сайты. Если вы нажмете на внешнюю ссылку, вы будете перенаправлены на этот сайт. Обратите внимание, что эти внешние сайты не управляются [мной/нами]. Поэтому [я/мы] настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности этих веб-сайтов. [Я/Мы] не контролируем и не несем ответственности за содержание, политику конфиденциальности или практику любых сторонних сайтов или услуг.</p>
      <h3>Конфиденциальность детей</h3>
      <p>Эти Сервисы не предназначены для лиц младше 13 лет. [Я/Мы] не собираем сознательно личную информацию от детей младше 13 лет. В случае, если [я/мы] обнаружим, что ребенок младше 13 лет предоставил нам личную информацию, [я/мы] немедленно удалим ее с наших серверов. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок предоставил нам личную информацию, пожалуйста, свяжитесь с [мной/нами], чтобы [я/мы] могли предпринять необходимые действия.</p>
      <h3>Изменения в Политике конфиденциальности</h3>
      <p>[Я/Мы] можем обновлять нашу Политику конфиденциальности время от времени. Таким образом, вам рекомендуется периодически просматривать эту страницу на предмет изменений. [Я/Мы] уведомим вас о любых изменениях, опубликовав новую Политику конфиденциальности на этой странице.
      
      Эта политика действует с 20 марта 2023 года.</p>
      <h3>Свяжитесь с нами</h3>
      <p>Если у вас есть какие-либо вопросы или предложения по поводу нашей Политики конфиденциальности, не стесняйтесь обращаться к нам по адресу .</p>`,
      aboutUs: "О нас",
      support: "Поддержка",
      contacts: "Контакты",
      contactUs: "Связаться с нами",
      search: "Поиск",
      info: "Информация",
      allRightsReserved: "Все права защищены",
      followUsOnSocial: "Скачайте наше приложение",
      promotions: "Акции",
      promo: "Акция",
      pair: "В паре",
      popularModels: "Популярные модели",
      sale: "Распродажа",
      allProducts: "Все товары",
      newsAndArticles: "Новости и статьи",
      sortBy: "Сортировать по",
      ASC: "По возрастанию",
      DESC: "По убыванию",
      promoASC: "Акции по возрастанию",
      promoDESC: "Акции по убыванию",
      select: "Выбрать",
      resetFilters: "Сбросить фильтры",
      apply: "Применить",
      price: "Цена",
      available: "В наличии",
      notAvailable: "Нет в наличии",
      alreadyInCart: "Товар уже в корзине",
      aviableNotify: "Уведомить о наличии товара",
      alreadyNotify:
        "Мы отправим вам уведомление, как только товар появится в наличии",
      productCode: "Код товара",
      buy: "Купить",
      characteristics: "Характеристики",
      description: "Описание",
      reviews: "Отзывы",
      writeReview: "Написать отзыв",
      leaveFeedback: "Оставить отзыв",
      name: "Имя",
      openTgBot: "Открыть Telegram бота",
      surname: "Фамилия",
      email: "Email",
      mark: "Оценка",
      message: "Сообщение",
      showInstruction: "Показать инструкцию",
      minimalCount: "Минимальное количество заказа для этого товара",
      noReviews: "Отзывов пока нет. Вы можете быть первым!",
      goToHomePage: "Перейти на главную",
      title404: "Страница не найдена",
      message404: "Извините, мы не можем найти страницу, которую вы ищете.",
      queryResult: "Результаты по запросу",
      queryNoResult: "По запросу ничего не найдено",
      noResultMessage1:
        "К сожалению, мы не смогли найти товары по вашему запросу.",
      noResultMessage2: "Попробуйте изменить параметры поиска.",
      searchMinLengthError:
        "Поисковый запрос должен содержать не менее 2 символов.",
      login: "Войти",
      register: "Зарегистрироваться",
      forgotPass: "Забыли пароль",
      pass: "Пароль",
      emailOrPhone: "Email или телефон в формате +380",
      continue: "Продолжить",
      phone: "Телефон",
      confirmPass: "Подтвердите пароль",
      country: "Страна",
      region: "Область",
      terms: "Я прочитал и согласен с",
      termsWord: "политикой конфиденциальности",
      passwordnotSame: "Пароли не совпадают",
      passwordLength: "Пароль должен быть не менее 6 символов",
      company: "Компания",
      yourName: "Ваше имя",
      yourSurname: "Ваша фамилия",
      enterCompany: "Введите название компании",
      enter: "Ввести",
      error: "Ошибка!",
      chooseCountry: "Пожалуйста, выберите страну",
      chooseCity: "Пожалуйста, выберите город",
      chooseWarehouse: "Пожалуйста, выберите отделение Новой Почты",
      chooseState: "Пожалуйста, выберите область",
      invalidPhone: "Неверный формат номера телефона",
      confirm: "Подтвердить",
      messagesIsRead: "Все сообщения прочитаны!",
      confirmCodeDescr:
        "Пожалуйста, введите код подтверждения, отправленный на ваш номер телефона.",
      confirmCodeDescrEmail:
        "Пожалуйста, введите код подтверждения, отправленный на email.",
      enterCode: "Ваш код подтверждения",
      phoneCode: "Код телефона",
      success: "Успешно!",
      notEmailOrPhone:
        "Вы ввели неверный формат email или телефона. Проверьте данные и попробуйте снова.",
      resendCode: "Отправить код повторно",
      maxResendCount: "Превышено максимальное количество отправок.",
      codeAgainSend: "Код успешно отправлен! Проверьте телефон.",
      codeAgainSendEmail: "Код успешно отправлен! Проверьте email.",
      profile: "Профиль",
      favorites: "Избранное",
      cart: "Корзина",
      logOut: "Выйти",
      yes: "Да",
      no: "Нет",
      loginOrRegisterTitle: "Войдите для продолжения",
      loginOrRegister: "Войти или зарегистрироваться",
      quantity: "Количество",
      remove: "Удалить",
      send: "Отправить",
      setArticleRating: "Помогите улучшить контент, оценив эту новость.",
      emptyFavorites: "В избранном пока нет товаров.",
      emptyFavorites2:
        "Добавьте понравившиеся товары в избранное для быстрого доступа.",
      successAddedFavorite: "Товар успешно добавлен в избранное.",
      successAddedWaiting: "Товар добавлен в лист ожидания.",
      successDeleteFavorite: "Товар удален из избранного.",
      successDeleteWaiting: "Товар удален из листа ожидания.",
      successAddedToOrder: "Товар добавлен в корзину.",
      attention: "Внимание",
      afterRegisterText:
        "С вами свяжутся в течение двух рабочих дней для уточнения информации.",
      ok: "Хорошо",
      reviewMessage: "Напишите ваш отзыв здесь...",
      reviewSent: "Спасибо за отзыв!",
      reviewSentInfo: "После проверки модератором он будет опубликован.",
      cancel: "Отмена",
      orders: "Мои заказы",
      clearCart: "Очистить корзину",
      toOrdering: "Оформить заказ",
      emptyCart1: "Ваша корзина пуста.",
      emptyCart2: "Добавьте товары в корзину.",
      retailPrice: "Розничная цена",
      wholesalePrice: "Оптовая цена",
      largeWholesalePrice: "Партнерская",
      partnerPrice: "Партнерская цена",
      specialPrice: "Специальная цена",
      total: "Итого",
      emptyOrders1: "У вас пока нет заказов.",
      emptyOrders2: "Сделайте первый заказ.",
      emptyClosedOrders: "Нет завершенных заказов.",
      pending: "В обработке",
      closed: "Завершено",
      piece: "шт.",
      status: "Статус",
      comment: "Комментарий",
      yourComment: "Ваш комментарий",
      yourMessage: "Ваше сообщение",
      yourAddress: "Ваш адрес",
      writeUs: "Напишите нам",
      discount: "Скидка",
      paymentMethod: "Способ оплаты",
      fact: "Фактический",
      nonFact: "Не фактический",
      workHour: "Время работы",
      workHourVal: "Пн-Сб: 9:00-21:00, Вс: выходной.",
      saleDep: "Отдел продаж",
      techDep: "Техподдержка",
      orderType: "Способ доставки",
      delivery: "Доставка Новой Почтой курьером",
      pickup: "Самовывоз (г. Харьков)",
      deliveryToWarehouse: "Доставка в отделение Новой Почты",
      city: "Город",
      state: "Область",
      warehouse: "Отделение",
      address: "Адрес",
      processOrder: "Оформить заказ",
      factPay: "Оплата при получении (без отсрочки). Предоставляется скидка 5%",
      chooseOrderType: "Выберите способ доставки",
      warehouseNP: "В отделение Новой Почты",
      courierNP: "Курьером Новой Почты",
      pickupAddress:
        "г. Харьков, пер. Ващенковский 28Б, 1 этаж, магазин «Автозвук»",
      address2: "пер. Ващенковский 28Б, Харьков, Украина",
      pickupText1: "После оформления заказа",
      pickupStrongText: "отмеченные товары резервируются на 3 дня.",
      pickupText2: "По истечении срока бронь снимается.",
      totalSum: "Итоговая сумма",
      addedToOrder: "В корзине",
      currentOrders: "Текущие заказы",
      historyOrders: "История заказов",
      thanksForOrder: "Спасибо за заказ!",
      order: "Заказ",
      orderInfo: "Менеджер свяжется с вами для уточнения деталей.",
      uah: "грн",
      грн: "грн",
      ye: "у.е.",
      change: "Изменить",
      noChange: "Данные не изменены",
      confirmEmail: "Подтвердите email.",
      confirmEmailText1: "Мы отправили письмо с ссылкой для подтверждения.",
      confirmEmailText2: "Проверьте папку «Спам» или свяжитесь с поддержкой.",
      notifications: "Уведомления",
      emptyNotifications: "Уведомлений пока нет.",
      emptyNotifyProducts: "Нет товаров в листе ожидания.",
      developed: "Разработано",
      promoCode: "Промокод",
      applied: "применен",
      notifyTitle: "Лист ожидания",
      downloadPriceList: "Скачать прайс",
      downloadShortPriceList: "Скачать краткий прайс",
      downloadXML: "Скачать XML",
      guiideByChoice: "Гид по выбору",
      waitTwoMinutes: "Загрузка займет около 2 минут",
      publicOfferAgreement: "Договор публичной оферты",
      successConfirmEmail: "Email успешно подтвержден!",
      failConfirmEmail: "Не удалось подтвердить email.",
      warehouseAvailable: "Наличие на складах",
      storage: "Склад",
      availability: "Наличие",
      allWarehouses: "Все склады",
      toOrder: "Под заказ 2-3 дня",
      noResultByFilter: "По выбранному фильтру ничего не найдено.",
      errorAuth: "Ошибка авторизации. Обратитесь в поддержку.",
      this_phone_number_is_already_in_use: "Номер телефона уже используется",
      this_email_is_already_in_use: "Email уже используется",
      this_email_already_in_use: "Email уже используется",
      today_max_phone_code_amount_expired: "Превышен лимит запросов кода",
      code_is_expite_or_wrong: "Код устарел или неверен",
      login_or_password_wrong: "Неверный логин или пароль",
      user_not_found: "Пользователь не найден",
      please_confirm_your_phone: "Подтвердите номер телефона.",
      agreeLogout: "Вы уверены, что хотите выйти?",
      agreeDelete: "Очистить корзину?",
      agreeClearAllMessages: "Пометить все сообщения как прочитанные?",
      agreeDeleteOne: "Удалить этот товар?",
      promo_code_not_found: "Промокод не найден",
      promo_code_expired: "Промокод просрочен",
      promo_code_usage_amount_exhausted:
        "К сожалению, количество использований данного промокода исчерпано. Пожалуйста, попробуйте использовать другой промокод.",
      you_already_use_this_promo_code: "Вы уже использовали этот промокод.",
      warrantyTermsTitle0: "Договор публичной оферты",
      warrantyTermsText0: `Согласно данному Договору, одна сторона, Продавец, с одной стороны, и любое лицо, принявшее условия данной Публичной оферты - Покупатель, с другой стороны, далее вместе именуемые Стороны, заключили настоящий Договор публичной оферты (далее - Договор), адресованный неограниченному кругу лиц, являющийся официальным публичным предложением Продавца заключить договор купли-продажи Товара с Покупателями, фото которого размещены в соответствующем разделе Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">https://apulse.com.ua/</a>.
Продавцы, намеревающиеся продать Товар через Сайт <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> и Покупатели, приобретающие Товар, изображение которого размещено на соответствующих страницах <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, принимают условия настоящего Договора на следующем.`,
      warrantyTermsTitle1: "ОБЩИЕ ПОЛОЖЕНИЯ",
      warrantyTermsText1: `Договорные отношения между Продавцом и Покупателем оформляются в виде Договора публичной оферты. Нажатие кнопки «ЗАКАЗАТЬ» на странице Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> в соответствующем разделе означает, что Покупатель, независимо от статуса (физическое лицо, юридическое лицо, физическое лицо-предприниматель), в соответствии с действующим международным и украинским законодательством, принял условия Договора публичной оферты, перечисленные ниже.
1.2. Договор публичной оферты является публичным, то есть, согласно статьям 633, 641 Гражданского кодекса Украины, его условия одинаковы для всех Покупателей независимо от статуса (физическое лицо, юридическое лицо, физическое лицо-предприниматель). В случае полного согласия с данным Договором Покупатель принимает условия заказа, оплаты и доставки товара Продавцом, ответственность за недобросовестный Заказ и за невыполнение условий данного Договора.
1.3. Настоящий Договор вступает в силу с момента нажатия на кнопку «ЗАКАЗАТЬ», посредством чего Покупатель соглашается на приобретение Товара, имеющегося у Продавца, и действует до момента получения Покупателем Товара от Продавца и полной его оплаты.
1.4. Для регулирования договорных правоотношений по Договору Стороны выбирают и, при необходимости, применяют законодательство Украины. Если международным договором, ратифицированным Верховной Радой Украины, установлены иные правила, чем предусмотренные законодательством Украины, применяются правила международного договора.`,
      warrantyTermsTitle2: "ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ",
      warrantyTermsText2: `«Договор публичной оферты» - публичный договор, образец которого размещен на Сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> и использование которого обязательно для всех Продавцов, содержащий предложение Продавца о приобретении Товара, изображение которого размещено на Сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, направленное неопределенному кругу лиц, в том числе Покупателям.
«Акцепт» - принятие Покупателем предложения Продавца о приобретении Товара, изображение которого размещено на Сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, путем добавления его в виртуальную корзину и отправки Заказа.
«Товар» - предмет торговли (товар, модель, аксессуар, комплектующие и сопутствующие предметы, любые другие предметы торговли), приобретение которого размещено на Сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> предложение продавца.
«Покупатель» - любое дееспособное физическое лицо, юридическое лицо, физическое лицо-предприниматель, в соответствии с действующим международным и украинским законодательством, посетившее Сайт <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> и намеревающееся приобрести конкретный Товар.
«Продавец» - любое дееспособное физическое лицо, юридическое лицо, физическое лицо-предприниматель, в соответствии с действующим международным и украинским законодательством, владеющее или распространяющее Товар и намеревающееся продать его через Сайт <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>.
«Заказ» - надлежащим образом оформленная и размещенная на Сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> заявка Покупателя на покупку Товара, адресованная Продавцу.
«Законодательство» - нормы, установленные украинским или международным законодательством для регулирования договорных правоотношений по Договору.
«Существенный недостаток Товара» - недостаток, который делает невозможным или недопустимым использование Товара в соответствии с его целевым назначением, возник по вине производителя (Продавца), после его устранения проявляется вновь по независящим от потребителя причинам.`,
      warrantyTermsTitle3: "ПРЕДМЕТ ДОГОВОРА",
      warrantyTermsText3: `3.1. Продавец обязуется продать Товар на условиях и в порядке, определенных настоящим Договором, на основании размещенного Покупателем Заказа на соответствующей странице Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, а Покупатель обязуется купить товар и оплатить его на условиях и в порядке, определенных настоящим Договором.
3.2. Продавец гарантирует, что Товар не находится в залоге, не обременен спором, не арестован, а также на него не распространяются права третьих лиц.
3.3 Продавец и Покупатель подтверждают, что настоящий Договор не является мнимым или притворным Договором либо Договором, заключенным под влиянием давления или обмана.
3.4. Продавец подтверждает, что имеет все необходимые разрешения на осуществление хозяйственной деятельности, регулирующие сферу возникающих и действующих в ходе исполнения настоящего Договора правоотношений, а также гарантирует, что обладает правом на изготовление и/или продажу товара без каких-либо ограничений, в соответствии с требованиями действующего законодательства Украины, и обязуется нести ответственность в случае нарушения прав Покупателя в ходе исполнения настоящего Договора и реализации Товара.`,
      warrantyTermsTitle4: "ПРАВА И ОБЯЗАННОСТИ ПРОДАВЦА",
      warrantyTermsText4: `4.1 Продавец обязан:
      <ul>
				<li>выполнять условия настоящего Договора</li>
        <li>выполнить Заказ Покупателя в случае получения оплаты от Покупателя;</li>
        <li>передать Товар Покупателю в соответствии с выбранным образцом на соответствующей странице Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, размещенным заказом и условиями настоящего Договора;</li>
        <li>проверять качественные и количественные характеристики Товара при его упаковке на складе;</li>
			</ul>
4.2. Продавец вправе:
			<ul>
        <li>в одностороннем порядке прекратить оказание услуг по настоящему Договору в случае нарушения Покупателем условий настоящего Договора.</li>
			</ul>`,
      warrantyTermsTitle5: "ПРАВА И ОБЯЗАННОСТИ ПОКУПАТЕЛЯ",
      warrantyTermsText5: `5.1. Покупатель обязан:
				<ul>
					<li>своевременно оплатить и получить заказ на условиях настоящего Договора;</li>
      	  <li>ознакомиться с информацией о товаре, размещенной на Сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>при получении Товара от лица, осуществившего его доставку, проверить целостность и комплектность Товара путем осмотра содержимого упаковки. В случае повреждения или неполной комплектности Товара зафиксировать их в акте, который вместе с Покупателем должен подписать лицо, осуществившее его доставку Покупателю.</li>
				</ul>
5.2. Покупатель вправе:
				<ul>
        	<li>разместить заказ на соответствующей странице Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>требовать от Продавца выполнения условий настоящего Договора;</li>
				</ul>
				`,
      warrantyTermsTitle6: "ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА",
      warrantyTermsText6: `6.1. Покупатель самостоятельно размещает заказ на соответствующей странице Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> путем добавления Товара в виртуальную корзину нажатием кнопки «Добавить в корзину!», либо оформляет заказ по электронной почте или позвонив по контактному номеру телефона, указанному в разделе контактов Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>
6.2. Срок формирования Заказа составляет до 2 рабочих дней с момента его оформления. В случае отправки заказа в выходной или праздничный день срок формирования отсчитывается с первого рабочего дня после выходных.`,
      warrantyTermsTitle7: "ЦЕНА ДОГОВОРА И ПОРЯДОК ОПЛАТЫ",
      warrantyTermsText7: `7.1 Цена каждого отдельного Товара определяется Продавцом и указывается на соответствующей странице Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>. Цена Договора определяется путем сложения цен всех выбранных Товаров, находящихся в виртуальной корзине, и цены доставки, которая определяется в зависимости от способа доставки в соответствии с условиями Раздела 8 настоящего Договора.
7.2. Стоимость Заказа может варьироваться в зависимости от цены, количества или ассортимента товаров.
7.3. Покупатель может оплатить заказ следующими способами:
				<ol>
					<li>безналичным перечислением денег на расчетный счет Продавца, указанный в счете, в том числе через интернет-банкинг (Покупатель оплачивает заказ в течение трех дней с момента получения счета в размере 100% предоплаты);</li>
					<li>наложенным платежом при получении Заказа в представительстве службы доставки на территории Украины или на территории другой страны в соответствии с местом оформления заказа товара;</li>
					<li>любым другим способом по согласованию с Продавцом.</li>
				</ol>
				`,
      warrantyTermsTitle8: "УСЛОВИЯ ДОСТАВКИ ТОВАРА",
      warrantyTermsText8: `8.1. Покупатель получает Товар путем доставки или получает его лично. Порядок оплаты и получения указан на соответствующей странице Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>
8.2. При доставке Товара в другие города Украины или на территорию другой страны, осуществляемой иными Службами доставки (далее - Компании-перевозчики), Покупатель полностью и безоговорочно соглашается с Правилами перевозки грузов данными компаниями-перевозчиками.
8.3. Факт получения Товара и отсутствие претензий к качеству доставленного Товара Компаниями-перевозчиками подтверждается Покупателем собственноручной подписью в товарно-транспортной накладной, декларации Компании-перевозчика, либо в расходной накладной при получении Товара. Со своей стороны Продавец гарантирует отгрузку Товара Компании-перевозчику в количестве, указанном и оплаченном Покупателем, в полной комплектности согласно спецификации Товара и в надлежащем (рабочем) состоянии и качестве.
8.4. В случае отсутствия Покупателя по указанному Покупателем адресу доставки либо отказа Покупателя от получения Товара по необоснованным причинам, при доставке Курьером компании-перевозчика, Товар подлежит возврату в центр отправки. Оплата услуг Компании-перевозчика удерживается из суммы, уплаченной Покупателем за Товар. Остаток суммы подлежит возврату Покупателю на основании его письма, направленного на e-mail: <a href="mailto:info@apulse.com.ua">info@apulse.com.ua</a> с указанием текущего счета, на который следует вернуть средства. Все вопросы, возникающие в процессе оплаты и получения Товара, Покупатель может уточнить, воспользовавшись контактной информацией в разделе Контакты.`,
      warrantyTermsTitle9: "УСЛОВИЯ ВОЗВРАТА ТОВАРА",
      warrantyTermsText9: `9.1. В соответствии со ст. 9 Закона Украины «О защите прав потребителей», Покупатель имеет право обменять Товар надлежащего качества в течение четырнадцати дней, не считая дня покупки, если более длительный срок не объявлен Продавцом. Покупатель имеет право на обмен Товара с учетом положений закона относительно оснований и перечня Товаров, не подлежащих обмену (возврату).
9.2. В случае намерения Покупателя вернуть Товар, такой возврат осуществляется согласно разделу Сайта «Возвраты», с учетом действующих на территории Украины или на территории другой страны в соответствии с местом получения Товара условий и правил перевозчика или курьера.
9.3. При обнаружении хотя бы одного из перечисленных недостатков Покупатель обязан зафиксировать его в акте произвольной формы. Акт должен быть подписан Покупателем и лицом, осуществившим доставку Товара либо Продавцом. При возможности недостатки следует зафиксировать посредством фото- или видеосъемки. В течение 1 (одного) дня Покупатель обязан уведомить менеджера (представителя Продавца, ответственного за оформление заказа на Товар) о выявленных недостатках и согласовать замену Товара, одновременно заполнив форму рекламации на возврат Товара на сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>`,
      warrantyTermsTitle10: "ОТВЕТСТВЕННОСТЬ СТОРОН И РАЗРЕШЕНИЕ СПОРОВ",
      warrantyTermsText10: `10.1. Стороны несут ответственность за невыполнение или ненадлежащее выполнение условий настоящего Договора в порядке, предусмотренном настоящим Договором и действующим международным и украинским законодательством.
10.2. В случае возникновения споров, связанных с исполнением Сторонами настоящего Договора, за исключением споров о взыскании задолженности с Покупателя, Стороны обязуются разрешать их путем переговоров с соблюдением претензионного порядка. Срок рассмотрения претензии составляет 7 (семь) календарных дней с момента ее получения. По спорам, связанным с взысканием задолженности с Покупателя, соблюдение претензионного порядка не требуется.
10.3. Все споры, разногласия или претензии, возникающие из или в связи с настоящим Договором, в том числе связанные с его исполнением, нарушением, прекращением или недействительностью, разрешаются в соответствующем суде в соответствии с международным и украинским материальным и процессуальным правом.`,
      warrantyTermsTitle11: "ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ",
      warrantyTermsText11: `11.1. Стороны не несут ответственности за невыполнение любого из своих обязательств, за исключением обязательств по оплате, если докажут, что такое невыполнение было вызвано обстоятельствами непреодолимой силы, т.е. событиями или обстоятельствами, которые действительно находятся вне контроля такой стороны, возникли после заключения настоящего Договора, являются непредвиденными и неизбежными.
        К обстоятельствам непреодолимой силы относятся, в частности, стихийные бедствия, забастовки, пожары, наводнения, взрывы, обледенение, войны (как объявленные, так и необъявленные), беспорядки, утрата товара, задержки перевозчиков, вызванные авариями или неблагоприятными погодными условиями, опасности и аварии на море, эмбарго, катастрофы, ограничения, введенные государственными органами (включая распределение, приоритеты, официальные требования, квоты и контроль за ценами), если эти обстоятельства непосредственно влияют на выполнение настоящего Договора.
11.2. Сторона, для которой стало невозможным выполнение своих обязательств по настоящему Договору в связи с наступлением обстоятельств непреодолимой силы, незамедлительно информирует другую Сторону в письменной форме о возникновении вышеуказанных обстоятельств, а также предоставляет другой Стороне подтверждение наличия обстоятельств непреодолимой силы в течение 30 (тридцати) календарных дней. Таким подтверждением будет служить справка, сертификат или иной соответствующий документ, выданный уполномоченным государственным органом, находящимся по месту наступления обстоятельств непреодолимой силы.
11.3. Срок, необходимый Сторонам для выполнения своих обязательств по настоящему Договору, продлевается на любой период, в течение которого выполнение было отложено в связи с вышеуказанными обстоятельствами.
11.4. Если в силу обстоятельств непреодолимой силы невыполнение обязательств по настоящему Договору продолжается более трех месяцев, каждая из Сторон имеет право в одностороннем порядке прекратить действие настоящего Договора, уведомив об этом другую Сторону в письменной форме. Несмотря на наступление обстоятельств непреодолимой силы, до прекращения действия настоящего Договора в связи с обстоятельствами непреодолимой силы Стороны производят окончательные взаиморасчеты.`,
      warrantyTermsTitle12: "ДРУГИЕ УСЛОВИЯ",
      warrantyTermsText12: `12.1. Предоставленная Покупателем информация является конфиденциальной. Информация о Покупателе используется исключительно в целях выполнения его Заказа (отправка сообщения продавцу о заказе Товара, отправка рекламных сообщений и т.п.)
12.2. Посредством собственного акцепта Договора либо регистрации на Сайте <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> (заполнение регистрационной формы) Покупатель добровольно соглашается на сбор и обработку своих персональных данных в следующих целях: ставшие известными данные будут использоваться в коммерческих целях, в том числе для обработки заказов на покупку товара, получения информации о заказе, отправки рекламных и специальных предложений, информации об акциях, распродажах или любой иной информации о деятельности Сайта <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> посредством средств телекоммуникационной связи (электронная почта, мобильная связь). Для целей, предусмотренных настоящим пунктом, Покупатель дает право направлять письма, сообщения и материалы на почтовый адрес, электронную почту Покупателя, а также отправлять SMS-сообщения, осуществлять звонки на указанный в анкете номер телефона.
12.3. Покупатель дает право на обработку своих персональных данных, включая: размещение персональных данных в базах данных (без дополнительного уведомления), осуществление бессрочного хранения данных, их накопление, обновление, изменение (по мере необходимости). Продавец обязуется обеспечить защиту данных от несанкционированного доступа третьих лиц, не распространять и не передавать данные любым третьим лицам (за исключением передачи данных аффилированным лицам, коммерческим партнерам, лицам, уполномоченным Продавцом на непосредственную обработку данных в указанных целях, а также по обязательному требованию компетентного государственного органа).
12.4. В случае нежелания получать рассылку Покупатель вправе обратиться к Продавцу, написав заявление об отказе от получения рекламных материалов, направив его на почтовый или электронный адрес.
12.5. Продавец не несет ответственности за содержание и достоверность информации, предоставленной Покупателем при оформлении заказа. Покупатель несет ответственность за достоверность предоставленной информации при оформлении заказа.`,
    },
  },
  pl: {
    translation: {
      days: "dni",
      hours: "godzin",
      minutes: "minut",
      seconds: "sekund",
      left: "pozostało",
      leftToEnd: "Do końca promocji",
      day1: "dzień",
      day2: "dni",
      day3: "dni",
      finished: "zakończono",
      promoDetails: "Szczegóły promocji",
      promoFrom: "Promocja obowiązuje od",
      promoTo: "do",
      promoFinished: "Zakończona",
      emptyStateTitle: "Lista jest pusta",
      emptyStateDesc:
        "Przepraszamy, obecnie nie ma dostępnych promocji. Spróbuj ponownie później lub skontaktuj się z obsługą klienta.",
      productsCatalog: "Katalog produktów",
      catalog: "Katalog",
      news: "Aktualności",
      warrantyConditions: "Umowa oferty publicznej",
      privacyPolicy: "Polityka prywatności",
      privacyPolicyText: `
      <p>Denis Matiushenko jest właścicielem i twórcą aplikacji „Apulse” jako bezpłatnej aplikacji. Ta USŁUGA jest świadczona przez Denis Matiushenko bezpłatnie i przeznaczona do użytku „tak jak jest”.
      
      Ta strona służy do informowania odwiedzających o naszych zasadach dotyczących gromadzenia, wykorzystywania i ujawniania Informacji Osobistych, jeśli ktoś zdecyduje się skorzystać z naszych Usług.
      
      Jeśli zdecydujesz się korzystać z naszych Usług, zgadzasz się na gromadzenie i wykorzystywanie informacji zgodnie z niniejszą polityką. Informacje osobiste, które zbieramy, są wykorzystywane do świadczenia i ulepszania Usługi. Nie będziemy używać ani udostępniać Twoich informacji nikomu, z wyjątkiem przypadków opisanych w niniejszej Polityce prywatności.
      
      Terminy użyte w niniejszej Polityce prywatności mają takie samo znaczenie jak w naszych Warunkach korzystania z usługi, które są dostępne, chyba że w niniejszej Polityce prywatności określono inaczej.</p>
      <h3>Gromadzenie i wykorzystywanie informacji</h3>
      <p>Aby poprawić komfort korzystania z naszych Usług, możemy wymagać podania przez Ciebie pewnych danych osobowych [dodaj wszystko, co zbierasz, np. nazwę użytkownika, adres, lokalizację, zdjęcia]. Żądane informacje będą [przechowywane na Twoim urządzeniu i w żaden sposób nie są przez nas zbierane].
      
      Aplikacja korzysta z usług stron trzecich, które mogą zbierać informacje służące do Twojej identyfikacji.
      
      Linki do polityk prywatności dostawców usług stron trzecich używanych przez aplikację:</p>
      <ul>
        <li>Google Play Services</li>
        <li>Firebase Crashlytics</li>
        <li>Dane dziennika</li>
      </ul>
      <p>Chcemy Cię poinformować, że za każdym razem, gdy korzystasz z naszych Usług, w przypadku błędu w aplikacji zbieramy dane i informacje (za pośrednictwem produktów stron trzecich) na Twoim telefonie, zwane Dziennikiem. Dane te mogą obejmować informacje takie jak adres IP Twojego urządzenia, nazwa urządzenia, wersja systemu operacyjnego, konfiguracja aplikacji podczas korzystania z naszych Usług, godzina i data korzystania z Usługi oraz inne statystyki.</p>
      <h3>Ciasteczka</h3>
      <p>Ciasteczka to pliki zawierające niewielką ilość danych, które są powszechnie używane jako anonimowe identyfikatory. Są one wysyłane do Twojej przeglądarki z odwiedzanych witryn internetowych i przechowywane w pamięci wewnętrznej Twojego urządzenia.
      
      Niniejsza Usługa nie używa wyraźnie tych „ciasteczek”. Jednak aplikacja może korzystać z kodu i bibliotek stron trzecich, które używają ciasteczek do zbierania informacji i ulepszania swoich usług. Masz możliwość zaakceptowania lub odrzucenia tych ciasteczek oraz otrzymywania powiadomień, gdy ciasteczko jest wysyłane na Twoje urządzenie. Jeśli zdecydujesz się odrzucić nasze ciasteczka, możesz nie być w stanie korzystać z niektórych części tej Usługi.</p>
      <h3>Dostawcy usług</h3>
      <p>[Ja/My] możemy zatrudniać firmy i osoby trzecie z następujących powodów:</p>
      <ul>
        <li>Ułatwienie korzystania z Usługi;</li>
        <li>Świadczenie Usługi w naszym imieniu;</li>
        <li>Wykonywanie usług związanych z Usługą; lub</li>
        <li>Pomoc w analizie sposobu korzystania z naszej Usługi.</li>
        <li>[Ja/My] chcemy poinformować użytkowników tej Usługi, że te strony trzecie mają dostęp do ich Informacji Osobistych. Powodem jest wykonywanie zadań powierzonych im w naszym imieniu. Jednak są one zobowiązane do nieujawniania i nieużywania tych informacji w żadnym innym celu.</li>
      </ul>
      <h3>Bezpieczeństwo</h3>
      <p>[Ja/My] doceniamy Twoje zaufanie w zakresie przekazywania nam Twoich Informacji Osobistych, dlatego staramy się używać komercyjnie akceptowalnych środków do ich ochrony. Pamiętaj jednak, że żadna metoda transmisji przez Internet ani metoda przechowywania elektronicznego nie jest w 100% bezpieczna i niezawodna, a [ja/my] nie możemy zagwarantować jej absolutnego bezpieczeństwa.</p>
      <h3>Linki do innych witryn</h3>
      <p>Niniejsza Usługa może zawierać linki do innych witryn. Jeśli klikniesz link zewnętrzny, zostaniesz przekierowany do tej witryny. Należy pamiętać, że te zewnętrzne witryny nie są obsługiwane przez [mnie/nas]. Dlatego [ja/my] zdecydowanie zalecamy zapoznanie się z Polityką prywatności tych witryn. [Ja/My] nie sprawujemy kontroli i nie ponosimy odpowiedzialności za treść, politykę prywatności lub praktyki jakichkolwiek witryn lub usług stron trzecich.</p>
      <h3>Prywatność dzieci</h3>
      <p>Te Usługi nie są przeznaczone dla osób poniżej 13 roku życia. [Ja/My] nie zbieramy świadomie informacji osobistych od dzieci poniżej 13 roku życia. W przypadku, gdy [ja/my] odkryjemy, że dziecko poniżej 13 roku życia przekazało nam dane osobowe, [ja/my] natychmiast usuniemy je z naszych serwerów. Jeśli jesteś rodzicem lub opiekunem i wiesz, że Twoje dziecko przekazało nam dane osobowe, skontaktuj się z [mną/nami], abyśmy mogli podjąć niezbędne działania.</p>
      <h3>Zmiany w Polityce prywatności</h3>
      <p>[Ja/My] możemy od czasu do czasu aktualizować naszą Politykę prywatności. Zaleca się więc okresowe przeglądanie tej strony pod kątem zmian. [Ja/My] poinformujemy Cię o wszelkich zmianach, publikując nową Politykę prywatności na tej stronie.
      
      Niniejsza polityka obowiązuje od 20 marca 2023 roku.</p>
      <h3>Skontaktuj się z nami</h3>
      <p>Jeśli masz jakiekolwiek pytania lub sugestie dotyczące naszej Polityki prywatności, nie wahaj się skontaktować z nami pod adresem .</p>`,
      aboutUs: "O nas",
      support: "Wsparcie",
      contacts: "Kontakty",
      contactUs: "Skontaktuj się z nami",
      search: "Szukaj",
      info: "Informacje",
      allRightsReserved: "Wszelkie prawa zastrzeżone",
      followUsOnSocial: "Pobierz naszą aplikację",
      promotions: "Promocje",
      promo: "Promocja",
      pair: "W parze",
      popularModels: "Popularne modele",
      sale: "Wyprzedaż",
      allProducts: "Wszystkie produkty",
      newsAndArticles: "Aktualności i artykuły",
      sortBy: "Sortuj według",
      ASC: "Rosnąco",
      DESC: "Malejąco",
      promoASC: "Promocje rosnąco",
      promoDESC: "Promocje malejąco",
      select: "Wybierz",
      resetFilters: "Resetuj filtry",
      apply: "Zastosuj",
      price: "Cena",
      available: "Dostępny",
      notAvailable: "Niedostępny",
      alreadyInCart: "Produkt już w koszyku",
      aviableNotify: "Powiadom o dostępności produktu",
      alreadyNotify:
        "Wyślemy Ci powiadomienie, gdy produkt będzie ponownie dostępny",
      productCode: "Kod produktu",
      buy: "Kup",
      characteristics: "Charakterystyka",
      description: "Opis",
      reviews: "Recenzje",
      writeReview: "Napisz recenzję",
      leaveFeedback: "Zostaw opinię",
      name: "Imię",
      openTgBot: "Otwórz bota Telegram",
      surname: "Nazwisko",
      email: "Email",
      mark: "Ocena",
      message: "Wiadomość",
      showInstruction: "Pokaż instrukcję",
      minimalCount: "Minimalna ilość zamówienia dla tego produktu",
      noReviews: "Brak recenzji. Możesz być pierwszy!",
      goToHomePage: "Przejdź do strony głównej",
      title404: "Strona nie znaleziona",
      message404: "Przepraszamy, nie możemy znaleźć strony, której szukasz.",
      queryResult: "Wyniki wyszukiwania",
      queryNoResult: "Brak wyników wyszukiwania",
      noResultMessage1:
        "Niestety, nie znaleźliśmy produktów pasujących do Twojego zapytania.",
      noResultMessage2: "Spróbuj zmienić parametry wyszukiwania.",
      searchMinLengthError: "Wyszukiwana fraza musi mieć co najmniej 2 znaki.",
      login: "Zaloguj się",
      register: "Zarejestruj się",
      forgotPass: "Zapomniałeś hasła",
      pass: "Hasło",
      emailOrPhone: "Email lub telefon w formacie +380",
      continue: "Kontynuuj",
      phone: "Telefon",
      confirmPass: "Potwierdź hasło",
      country: "Kraj",
      region: "Region",
      terms: "Przeczytałem i zgadzam się z",
      termsWord: "polityką prywatności",
      passwordnotSame: "Hasła nie są identyczne",
      passwordLength: "Hasło musi mieć co najmniej 6 znaków",
      company: "Firma",
      yourName: "Twoje imię",
      yourSurname: "Twoje nazwisko",
      enterCompany: "Wprowadź nazwę firmy",
      enter: "Wprowadź",
      error: "Błąd!",
      chooseCountry: "Wybierz kraj",
      chooseCity: "Wybierz miasto",
      chooseWarehouse: "Wybierz oddział Nova Poshta",
      chooseState: "Wybierz region",
      invalidPhone: "Nieprawidłowy format numeru telefonu",
      confirm: "Potwierdź",
      messagesIsRead: "Wszystkie wiadomości zostały przeczytane!",
      confirmCodeDescr:
        "Wprowadź kod weryfikacyjny wysłany na Twój numer telefonu.",
      confirmCodeDescrEmail:
        "Wprowadź kod weryfikacyjny wysłany na adres email.",
      enterCode: "Twój kod weryfikacyjny",
      phoneCode: "Kod telefonu",
      success: "Sukces!",
      notEmailOrPhone:
        "Wprowadziłeś nieprawidłowy format email lub numeru telefonu. Sprawdź dane i spróbuj ponownie.",
      resendCode: "Wyślij kod ponownie",
      maxResendCount: "Przekroczono maksymalną liczbę wysłanych kodów.",
      codeAgainSend: "Kod został ponownie wysłany! Sprawdź telefon.",
      codeAgainSendEmail: "Kod został ponownie wysłany! Sprawdź email.",
      profile: "Profil",
      favorites: "Ulubione",
      cart: "Koszyk",
      logOut: "Wyloguj się",
      yes: "Tak",
      no: "Nie",
      loginOrRegisterTitle: "Zaloguj się, aby kontynuować",
      loginOrRegister: "Zaloguj się lub zarejestruj",
      quantity: "Ilość",
      remove: "Usuń",
      send: "Wyślij",
      setArticleRating: "Pomóż nam ulepszyć treść, oceniając tę wiadomość.",
      emptyFavorites: "Brak produktów w ulubionych.",
      emptyFavorites2: "Dodaj ulubione produkty dla szybkiego dostępu.",
      successAddedFavorite: "Produkt dodano do ulubionych.",
      successAddedWaiting: "Produkt dodano do listy oczekujących.",
      successDeleteFavorite: "Produkt usunięto z ulubionych.",
      successDeleteWaiting: "Produkt usunięto z listy oczekujących.",
      successAddedToOrder: "Produkt dodano do koszyka.",
      attention: "Uwaga",
      afterRegisterText:
        "Skontaktujemy się z Tobą w ciągu dwóch dni roboczych.",
      ok: "OK",
      reviewMessage: "Napisz swoją recenzję tutaj...",
      reviewSent: "Dziękujemy za recenzję!",
      reviewSentInfo: "Po sprawdzeniu przez moderatora zostanie opublikowana.",
      cancel: "Anuluj",
      orders: "Moje zamówienia",
      clearCart: "Wyczyść koszyk",
      toOrdering: "Zamów",
      emptyCart1: "Twój koszyk jest pusty.",
      emptyCart2: "Dodaj produkty do koszyka.",
      retailPrice: "Cena detaliczna",
      wholesalePrice: "Cena hurtowa",
      largeWholesalePrice: "Partner",
      partnerPrice: "Cena partnerska",
      specialPrice: "Cena specjalna",
      total: "Razem",
      emptyOrders1: "Brak zamówień.",
      emptyOrders2: "Złóż pierwsze zamówienie.",
      emptyClosedOrders: "Brak zamkniętych zamówień.",
      pending: "W trakcie",
      closed: "Zamknięte",
      piece: "szt.",
      status: "Status",
      comment: "Komentarz",
      yourComment: "Twój komentarz",
      yourMessage: "Twoja wiadomość",
      yourAddress: "Twój adres",
      writeUs: "Napisz do nas",
      discount: "Rabat",
      paymentMethod: "Metoda płatności",
      fact: "Fakt",
      nonFact: "Nie fakt",
      workHour: "Godziny pracy",
      workHourVal: "Pn-Sb: 9:00-21:00, Nd: dzień wolny.",
      saleDep: "Dział sprzedaży",
      techDep: "Wsparcie techniczne",
      orderType: "Sposób dostawy",
      delivery: "Dostawa kurierem Nova Poshta",
      pickup: "Odbiór osobisty (Charków)",
      deliveryToWarehouse: "Dostawa do oddziału Nova Poshta",
      city: "Miasto",
      state: "Region",
      warehouse: "Oddział",
      address: "Adres",
      processOrder: "Złóż zamówienie",
      factPay: "Płatność przy odbiorze (5% rabatu)",
      chooseOrderType: "Wybierz sposób dostawy",
      warehouseNP: "Do oddziału Nova Poshta",
      courierNP: "Kurierem Nova Poshta",
      pickupAddress:
        "Charków, ul. Waszczenkowskij 28B, 1 piętro, sklep «Avtozvuk»",
      address2: "ul. Waszczenkowskij 28B, Charków, Ukraina",
      pickupText1: "Po złożeniu zamówienia",
      pickupStrongText: "zaznaczone towary są rezerwowane na 3 dni.",
      pickupText2: "Po upływie terminu rezerwacja jest anulowana.",
      totalSum: "Suma końcowa",
      addedToOrder: "W koszyku",
      currentOrders: "Aktualne zamówienia",
      historyOrders: "Historia zamówień",
      thanksForOrder: "Dziękujemy za zamówienie!",
      order: "Zamówienie",
      orderInfo: "Menadżer skontaktuje się w celu potwierdzenia.",
      uah: "UAH",
      грн: "UAH",
      ye: "j.u.",
      change: "Zmień",
      noChange: "Brak zmian w danych",
      confirmEmail: "Potwierdź swój email.",
      confirmEmailText1: "Wysłaliśmy email z linkiem potwierdzającym.",
      confirmEmailText2: "Sprawdź folder «Spam» lub skontaktuj się z pomocą.",
      notifications: "Powiadomienia",
      emptyNotifications: "Brak powiadomień.",
      emptyNotifyProducts: "Brak produktów na liście oczekujących.",
      developed: "Opracowano",
      promoCode: "Kod promocyjny",
      applied: "zastosowano",
      notifyTitle: "Lista oczekujących",
      downloadPriceList: "Pobierz cennik",
      downloadShortPriceList: "Pobierz skrócony cennik",
      downloadXML: "Pobierz XML",
      guiideByChoice: "Poradnik wyboru",
      waitTwoMinutes: "Ładowanie może zająć około 2 minut",
      publicOfferAgreement: "Umowa oferty publicznej",
      successConfirmEmail: "Email został potwierdzony!",
      failConfirmEmail: "Nie udało się potwierdzić email.",
      warehouseAvailable: "Dostępność w magazynach",
      storage: "Magazyn",
      availability: "Dostępność",
      allWarehouses: "Wszystkie magazyny",
      toOrder: "Na zamówienie 2-3 dni",
      noResultByFilter: "Brak produktów zgodnych z filtrem.",
      errorAuth: "Błąd autoryzacji. Skontaktuj się z pomocą.",
      this_phone_number_is_already_in_use: "Numer telefonu jest już używany",
      this_email_is_already_in_use: "Email jest już używany",
      this_email_already_in_use: "Email jest już używany",
      today_max_phone_code_amount_expired: "Przekroczono limit kodów na dziś",
      code_is_expite_or_wrong: "Kod wygasł lub jest błędny",
      login_or_password_wrong: "Błędny login lub hasło",
      user_not_found: "Użytkownik nie znaleziony",
      please_confirm_your_phone: "Potwierdź numer telefonu.",
      agreeLogout: "Czy na pewno chcesz się wylogować?",
      agreeDelete: "Czy wyczyścić koszyk?",
      agreeClearAllMessages: "Oznaczyć wszystkie wiadomości jako przeczytane?",
      agreeDeleteOne: "Usunąć ten produkt?",
      promo_code_not_found: "Nie znaleziono kodu promocyjnego",
      promo_code_expired: "Kod promocyjny wygasł",
      promo_code_usage_amount_exhausted:
        "Niestety, limit użyć tego kodu promocyjnego został wyczerpany. Prosimy spróbować użyć innego kodu.",
      you_already_use_this_promo_code: "Już użyłeś tego kodu promocyjnego.",
      warrantyTermsTitle0: "Umowa oferty publicznej",
      warrantyTermsText0: `Niniejszą Umową, jedna strona, Sprzedający, z jednej strony, oraz każda osoba, która zaakceptowała warunki niniejszej Umowy Publicznej Oferty - Kupujący, z drugiej strony, dalej zwane łącznie Stronami, zawarły niniejszą Umowę Publicznej Oferty (zwany dalej Umową), skierowaną do nieograniczonej liczby osób, będący oficjalną publiczną ofertą Sprzedającego na zawarcie umowy sprzedaży Towaru z Kupującymi, którego zdjęcia są zamieszczone w odpowiedniej sekcji Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">https://apulse.com.ua/</a>.
Sprzedający, którzy zamierzają sprzedać Towar poprzez Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> i Kupujący, nabywający Towar, którego obraz jest zamieszczony na odpowiednich stronach <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, akceptują warunki niniejszej Umowy na następujących zasadach.`,
      warrantyTermsTitle1: "POSTANOWIENIA OGÓLNE",
      warrantyTermsText1: `Stosunki umowne między Sprzedającym a Kupującym są regulowane w formie Umowy Publicznej Oferty. Kliknięcie przycisku „ZAMÓW” na stronie Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> w odpowiedniej sekcji oznacza, że Kupujący, niezależnie od statusu (osoba fizyczna, osoba prawna, przedsiębiorca), zgodnie z obowiązującym międzynarodowym i ukraińskim prawodawstwem, przyjął warunki Umowy Publicznej Oferty, wymienione poniżej.
1.2. Umowa Publicznej Oferty jest publiczna, tj. zgodnie z art. 633, 641 Kodeksu Cywilnego Ukrainy, jej warunki są jednakowe dla wszystkich Kupujących, niezależnie od statusu (osoba fizyczna, osoba prawna, przedsiębiorca). W przypadku pełnej zgody z niniejszą Umową Kupujący akceptuje warunki składania zamówień, płatności i dostawy towaru przez Sprzedającego, odpowiedzialność za nierzetelne Zamówienie oraz za niewykonanie warunków niniejszej Umowy.
1.3. Niniejsza Umowa wchodzi w życie od momentu kliknięcia przycisku „ZAMÓW”, za pomocą którego Kupujący wyraża zgodę na zakup Towaru dostępnego u Sprzedającego i obowiązuje do momentu otrzymania Towaru przez Kupującego od Sprzedającego i dokonania pełnej zapłaty.
1.4. Do regulacji stosunków umownych na podstawie Umowy Strony wybierają i, w razie potrzeby, stosują ustawodawstwo Ukrainy. Jeżeli ratyfikowana przez Radę Najwyższą Ukrainy umowa międzynarodowa ustanawia inne zasady niż przewidziane przez ustawodawstwo Ukrainy, stosuje się zasady umowy międzynarodowej.`,
      warrantyTermsTitle2: "TERMINY I DEFINICJE",
      warrantyTermsText2: `„Umowa Publicznej Oferty” – umowa publiczna, której wzór jest zamieszczony na Witrynie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> i której stosowanie jest obowiązkowe dla wszystkich Sprzedających, zawierająca ofertę Sprzedającego na zakup Towaru, którego obraz jest zamieszczony na Witrynie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, skierowaną do nieograniczonej liczby osób, w tym Kupujących.
„Akceptacja” – przyjęcie przez Kupującego oferty Sprzedającego na zakup Towaru, którego obraz jest zamieszczony na Witrynie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, poprzez dodanie go do wirtualnego koszyka i wysłanie Zamówienia.
„Towar” – przedmiot handlowy (produkt, model, akcesorium, komponenty i przedmioty towarzyszące, inne przedmioty handlowe), którego zakup jest zamieszczony na Witrynie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> oferta sprzedawcy.
„Kupujący” – każda zdolna do czynności prawnych osoba fizyczna, prawna, przedsiębiorca, zgodnie z obowiązującym międzynarodowym i ukraińskim prawodawstwem, która odwiedziła Witrynę <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> i zamierza nabyć konkretny Towar.
„Sprzedający” – każda zdolna do czynności prawnych osoba fizyczna, prawna, przedsiębiorca, zgodnie z obowiązującym międzynarodowym i ukraińskim prawodawstwem, posiadająca lub rozpowszechniająca Towar i zamierzająca go sprzedać poprzez Witrynę <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>.
„Zamówienie” – prawidłowo wypełniony i zamieszczony na Witrynie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> wniosek Kupującego o zakup Towaru, skierowany do Sprzedającego.
„Ustawodawstwo” – normy ustanowione przez ukraińskie lub międzynarodowe prawo w celu regulacji stosunków umownych na podstawie Umowy.
„Istotna wada Towaru” – wada, która uniemożliwia lub uniemożliwia korzystanie z Towaru zgodnie z jego przeznaczeniem, powstała z winy producenta (Sprzedającego), po jej usunięciu ponownie pojawia się z przyczyn niezależnych od konsumenta.`,
      warrantyTermsTitle3: "PRZEDMIOT UMOWY",
      warrantyTermsText3: `3.1. Sprzedający zobowiązuje się sprzedać Towar na warunkach i w sposób określony w niniejszej Umowie na podstawie Zamówienia złożonego przez Kupującego na odpowiedniej stronie Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, a Kupujący zobowiązuje się kupić towar i zapłacić za niego na warunkach i w sposób określony w niniejszej Umowie.
3.2. Sprzedający gwarantuje, że Towar nie jest objęty zastawem, nie jest obciążony sporem, nie jest zajęty, a także nie podlegają mu prawa osób trzecich.
3.3 Sprzedający i Kupujący potwierdzają, że niniejsza Umowa nie jest umową pozorną lub fikcyjną, ani umową zawartą pod wpływem presji lub oszustwa.
3.4. Sprzedający potwierdza, że posiada wszystkie niezbędne zezwolenia na działalność gospodarczą, regulujące sferę powstających i działających w trakcie wykonywania niniejszej Umowy stosunków prawnych, a także gwarantuje, że posiada prawo do wytwarzania i/lub sprzedaży towaru bez jakichkolwiek ograniczeń, zgodnie z wymogami obowiązującego prawodawstwa Ukrainy, i zobowiązuje się ponosić odpowiedzialność w przypadku naruszenia praw Kupującego w trakcie wykonywania niniejszej Umowy i sprzedaży Towaru.`,
      warrantyTermsTitle4: "PRAWA I OBOWIĄZKI SPRZEDAJĄCEGO",
      warrantyTermsText4: `4.1 Sprzedający jest obowiązany:
      <ul>
				<li>przestrzegać warunków niniejszej Umowy</li>
        <li>realizować Zamówienie Kupującego w przypadku otrzymania zapłaty od Kupującego;</li>
        <li>przekazać Towar Kupującemu zgodnie z wybranym wzorem na odpowiedniej stronie Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, złożonym zamówieniem i warunkami niniejszej Umowy;</li>
        <li>sprawdzać jakościowe i ilościowe cechy Towaru podczas jego pakowania w magazynie;</li>
			</ul>
4.2. Sprzedający ma prawo:
			<ul>
        <li>jednostronnie zaprzestać świadczenia usług na podstawie niniejszej Umowy w przypadku naruszenia przez Kupującego warunków niniejszej Umowy.</li>
			</ul>`,
      warrantyTermsTitle5: "PRAWA I OBOWIĄZKI KUPUJĄCEGO",
      warrantyTermsText5: `5.1. Kupujący jest obowiązany:
				<ul>
					<li>terminowo zapłacić i odebrać zamówienie na warunkach niniejszej Umowy;</li>
      	  <li>zapoznać się z informacją o towarze zamieszczoną na Witrynie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>przy odbiorze Towaru od osoby dokonującej dostawy sprawdzić jego integralność i kompletność poprzez sprawdzenie zawartości opakowania. W przypadku uszkodzenia lub niekompletności Towaru należy je odnotować w protokole, który wraz z Kupującym musi podpisać osoba dostarczająca Towar.</li>
				</ul>
5.2. Kupujący ma prawo:
				<ul>
        	<li>złożyć zamówienie na odpowiedniej stronie Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>;</li>
        	<li>wymagać od Sprzedającego przestrzegania warunków niniejszej Umowy;</li>
				</ul>
				`,
      warrantyTermsTitle6: "PROCEDURA SKŁADANIA ZAMÓWIENIA",
      warrantyTermsText6: `6.1. Kupujący samodzielnie składa zamówienie na odpowiedniej stronie Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>, dodając Towar do wirtualnego koszyka poprzez kliknięcie przycisku „Dodaj do koszyka!”, lub składa zamówienie pocztą elektroniczną lub dzwoniąc pod numer telefonu podany w sekcji kontaktów Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>
6.2. Termin realizacji Zamówienia wynosi do 2 dni roboczych od momentu jego złożenia. W przypadku złożenia zamówienia w dzień wolny od pracy lub święto termin realizacji liczy się od pierwszego dnia roboczego po weekendzie.`,
      warrantyTermsTitle7: "CENA UMOWY I PROCEDURA PŁATNOŚCI",
      warrantyTermsText7: `7.1 Cena każdego poszczególnego Towaru jest określana przez Sprzedającego i podana na odpowiedniej stronie Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a>. Cena Umowy jest określana poprzez zsumowanie cen wszystkich wybranych Towarów znajdujących się w wirtualnym koszyku oraz ceny dostawy, która jest określana w zależności od sposobu dostawy zgodnie z warunkami Sekcji 8 niniejszej Umowy.
7.2. Koszt Zamówienia może się różnić w zależności od ceny, ilości lub asortymentu towarów.
7.3. Kupujący może zapłacić za zamówienie w następujący sposób:
				<ol>
					<li>przelewem bankowym na rachunek Sprzedającego wskazany na fakturze, w tym za pośrednictwem bankowości internetowej (Kupujący dokonuje płatności w ciągu trzech dni od otrzymania faktury w wysokości 100% przedpłaty);</li>
					<li>za pobraniem przy odbiorze Zamówienia w oddziale firmy dostawczej na terytorium Ukrainy lub na terytorium innego kraju zgodnie z miejscem złożenia zamówienia towaru;</li>
					<li>w dowolny inny sposób uzgodniony ze Sprzedającym.</li>
				</ol>
				`,
      warrantyTermsTitle8: "WARUNKI DOSTAWY TOWARU",
      warrantyTermsText8: `8.1. Kupujący otrzymuje Towar poprzez dostawę lub odbiera go osobiście. Procedura płatności i odbioru jest wskazana na odpowiedniej stronie Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>
8.2. W przypadku dostawy Towaru do innych miast Ukrainy lub na terytorium innego kraju, dokonywanej przez inne Firmy Kurierskie (zwane dalej Firmy Przewoźników), Kupujący w pełni i bezwarunkowo zgadza się z Regulaminem przewozu towarów przez te firmy przewozowe.
8.3. Fakt odbioru Towaru i brak zastrzeżeń co do jakości dostarczonego Towaru przez Firmy Przewoźników jest potwierdzany przez Kupującego własnoręcznym podpisem na liście przewozowym, deklaracji Firmy Przewoźnika lub na fakturze wydatkowej przy odbiorze Towaru. Ze swojej strony Sprzedający gwarantuje wysyłkę Towaru do Firmy Przewoźnika w ilości wskazanej i opłaconej przez Kupującego, w komplecie zgodnie ze specyfikacją Towaru oraz w należytym (sprawnym) stanie i jakości.
8.4. W przypadku nieobecności Kupującego pod wskazanym adresem dostawy lub odmowy odbioru Towaru przez Kupującego z nieuzasadnionych powodów, przy dostawie przez Kuriera firmy przewozowej, Towar podlega zwrotowi do centrum wysyłkowego. Opłata za usługi Firmy Przewoźnika jest potrącana z kwoty zapłaconej przez Kupującego za Towar. Pozostała kwota podlega zwrotowi Kupującemu na podstawie jego pisma wysłanego na adres e-mail: <a href="mailto:info@apulse.com.ua">info@apulse.com.ua</a> z podaniem numeru rachunku, na który należy zwrócić środki. Wszelkie pytania dotyczące płatności i odbioru Towaru Kupujący może wyjaśnić, korzystając z informacji kontaktowych w sekcji Kontakty.`,
      warrantyTermsTitle9: "WARUNKI ZWROTU TOWARU",
      warrantyTermsText9: `9.1. Zgodnie z art. 9 Ustawy Ukrainy „O ochronie praw konsumentów”, Kupujący ma prawo wymienić Towar dobrej jakości w ciągu czternastu dni, nie licząc dnia zakupu, chyba że Sprzedający ogłosił dłuższy termin. Kupujący ma prawo do wymiany Towaru z uwzględnieniem przepisów prawa dotyczących podstaw i wykazu towarów niepodlegających wymianie (zwrotowi).
9.2. W przypadku zamiaru zwrotu Towaru przez Kupującego, taki zwrot jest dokonywany zgodnie z sekcją Witryny „Zwroty”, z uwzględnieniem warunków i zasad przewoźnika lub kuriera obowiązujących na terytorium Ukrainy lub na terytorium innego kraju zgodnie z miejscem odbioru Towaru.
9.3. W przypadku wykrycia co najmniej jednej z wymienionych wad Kupujący jest obowiązany odnotować ją w protokole dowolnej formy. Protokół musi być podpisany przez Kupującego i osobę dokonującą dostawy Towaru lub Sprzedającego. W miarę możliwości wady powinny być udokumentowane za pomocą fotografii lub nagrania wideo. W ciągu 1 (jednego) dnia Kupujący jest obowiązany powiadomić menedżera (przedstawiciela Sprzedającego odpowiedzialnego za złożenie zamówienia na Towar) o wykrytych wadach i uzgodnić wymianę Towaru, jednocześnie wypełniając formularz reklamacyjny dotyczący zwrotu Towaru na stronie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a>`,
      warrantyTermsTitle10: "ODPOWIEDZIALNOŚĆ STRON I ROZWIĄZYWANIE SPORÓW",
      warrantyTermsText10: `10.1. Strony ponoszą odpowiedzialność za niewykonanie lub nienależyte wykonanie warunków niniejszej Umowy w sposób przewidziany w niniejszej Umowie i obowiązującym międzynarodowym i ukraińskim prawodawstwem.
10.2. W przypadku sporów związanych z wykonaniem niniejszej Umowy przez Strony, z wyjątkiem sporów o windykację należności od Kupującego, Strony zobowiązują się do rozstrzygania ich poprzez negocjacje z zachowaniem trybu reklamacyjnego. Termin rozpatrzenia reklamacji wynosi 7 (siedem) dni kalendarzowych od dnia jej otrzymania. W przypadku sporów dotyczących windykacji należności od Kupującego zachowanie trybu reklamacyjnego nie jest wymagane.
10.3. Wszelkie spory, nieporozumienia lub roszczenia wynikające z niniejszej Umowy lub związane z jej wykonaniem, naruszeniem, rozwiązaniem lub nieważnością, będą rozstrzygane przez właściwy sąd zgodnie z międzynarodowym i ukraińskim prawem materialnym i procesowym.`,
      warrantyTermsTitle11: "OKOLICZNOŚCI NIEPRZEZWYCIĘŻONE",
      warrantyTermsText11: `11.1. Żadna ze Stron nie ponosi odpowiedzialności za niewykonanie któregokolwiek ze swoich zobowiązań, z wyjątkiem zobowiązań płatniczych, jeżeli wykaże, że takie niewykonanie było spowodowane okolicznościami nieprzezwyciężonymi, tj. zdarzeniami lub okolicznościami, które są rzeczywiście poza kontrolą takiej Strony, powstały po zawarciu niniejszej Umowy, są nieprzewidywalne i nieuniknione.
        Do okoliczności nieprzezwyciężonych zalicza się w szczególności klęski żywiołowe, strajki, pożary, powodzie, eksplozje, oblodzenia, wojny (zarówno wypowiedziane, jak i niewypowiedziane), zamieszki, utratę towaru, opóźnienia przewoźników spowodowane wypadkami lub niekorzystnymi warunkami pogodowymi, zagrożenia i awarie na morzu, embargo, katastrofy, ograniczenia nałożone przez organy państwowe (w tym alokacje, priorytety, wymagania urzędowe, limity i kontrola cen), jeżeli okoliczności te mają bezpośredni wpływ na wykonanie niniejszej Umowy.
11.2. Strona, dla której stało się niemożliwe wykonanie jej zobowiązań na mocy niniejszej Umowy z powodu zaistnienia okoliczności nieprzezwyciężonych, niezwłocznie powiadamia drugą Stronę na piśmie o wystąpieniu powyższych okoliczności, a także dostarcza drugiej Stronie potwierdzenie wystąpienia okoliczności nieprzezwyciężonych w ciągu 30 (trzydziestu) dni kalendarzowych. Takim potwierdzeniem będzie zaświadczenie, certyfikat lub inny odpowiedni dokument wydany przez upoważniony organ państwowy znajdujący się w miejscu wystąpienia okoliczności nieprzezwyciężonych.
11.3. Termin wymagany Stronom do wykonania ich zobowiązań na mocy niniejszej Umowy zostaje przedłużony o okres, w którym wykonanie zostało odroczone z powodu powyższych okoliczności.
11.4. Jeżeli w wyniku okoliczności nieprzezwyciężonych niewykonanie zobowiązań na mocy niniejszej Umowy trwa dłużej niż trzy miesiące, każda ze Stron ma prawo jednostronnie rozwiązać niniejszą Umowę, powiadamiając o tym drugą Stronę na piśmie. Pomimo wystąpienia okoliczności nieprzezwyciężonych, przed rozwiązaniem niniejszej Umowy z powodu okoliczności nieprzezwyciężonych Strony dokonują ostatecznych rozliczeń.`,
      warrantyTermsTitle12: "INNE POSTANOWIENIA",
      warrantyTermsText12: `12.1. Informacje przekazane przez Kupującego są poufne. Informacje o Kupującym są wykorzystywane wyłącznie w celu realizacji jego Zamówienia (wysłanie wiadomości do sprzedawcy o zamówieniu Towaru, wysyłka wiadomości reklamowych itp.).
12.2. Poprzez własną akceptację Umowy lub rejestrację na Witrynie <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua.</a> (wypełnienie formularza rejestracyjnego) Kupujący dobrowolnie wyraża zgodę na gromadzenie i przetwarzanie swoich danych osobowych w następujących celach: dane, które stały się znane, będą wykorzystywane w celach handlowych, w tym do przetwarzania zamówień na zakup towaru, otrzymywania informacji o zamówieniu, wysyłki ofert reklamowych i specjalnych, informacji o promocjach, wyprzedażach lub innych informacji o działalności Witryny <a href="https://apulse.com.ua/" target="_blank" rel="noreferrer">apulse.com.ua</a> za pośrednictwem środków komunikacji elektronicznej (e-mail, telefon komórkowy). W celach określonych w niniejszym punkcie Kupujący upoważnia do wysyłania listów, wiadomości i materiałów na adres pocztowy, adres e-mail Kupującego, a także do wysyłania wiadomości SMS, wykonywania połączeń telefonicznych na podany w formularzu numer telefonu.
12.3. Kupujący wyraża zgodę na przetwarzanie swoich danych osobowych, w tym: umieszczanie danych osobowych w bazach danych (bez dodatkowego powiadomienia), prowadzenie nieograniczonego przechowywania danych, ich gromadzenie, aktualizowanie, zmienianie (w miarę potrzeby). Sprzedający zobowiązuje się zapewnić ochronę danych przed nieuprawnionym dostępem osób trzecich, nie rozpowszechniać ani nie przekazywać danych jakimkolwiek osobom trzecim (z wyjątkiem przekazywania danych podmiotom powiązanym, partnerom handlowym, osobom upoważnionym przez Sprzedającego do bezpośredniego przetwarzania danych w określonych celach, a także na żądanie właściwego organu państwowego).
12.4. W przypadku niechęci otrzymywania przesyłek reklamowych Kupujący ma prawo zwrócić się do Sprzedającego, pisząc oświadczenie o rezygnacji z otrzymywania materiałów reklamowych i wysyłając je na adres pocztowy lub e-mail.
12.5. Sprzedający nie ponosi odpowiedzialności za treść i prawdziwość informacji przekazanych przez Kupującego przy składaniu zamówienia. Kupujący ponosi odpowiedzialność za prawdziwość przekazanych informacji przy składaniu zamówienia.`,
    },
  },
};

i18n.init({
  resources,
  lng: localStorage.getItem(LOCAL_STORAGE.language) || DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
